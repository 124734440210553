import React, { useState, useEffect } from "react";
import "./CheckoutQr.scss";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FormatMoney } from "../../../utils/formats";
import CopyButton from "../../comp/CopyButton";
import Warning from "../../comp/Warning";
import FawryPay from "../../../img/fawry.png";
import TupayChannels from "../../../img/tupay-channels.png";
import ShopeePay from "../../../img/shopeepay.png";
import ShopeePayQr from "../../../img/shopee_qr.png";

var QRCode = require("qrcode.react");

function CheckoutWallet(props) {
  const { t } = useTranslation();
  const region = props.tradeInfo?.app_info?.region;
  const [isQrExpired, setIsQrExpired] = useState(false);
  useEffect(() => {
    setIsQrExpired(!!localStorage?.getItem("PS_" + props.prepayId + "qr_expired"));
  }, []);

  const config = {
    FawryPay: {
      qr_img: props.payData?.qr_img,
      logo: FawryPay,
      reference: true,
    },
    Tupay: {
      qr_img: props.payData?.qr_img,
      logo: TupayChannels,
      reference: true,
    },
    "PER-QRCode-PGOne": {
      qr_img: props.payData?.qr_img,
      logo: TupayChannels,
      reference: true,
    },
    ShopeePay: {
      qr_str: props.payData?.qr_code,
      qr_icon: ShopeePayQr,
      logo: ShopeePay,
    },
  };

  const configData =
    config[`${region}-${props.method}-${props.payChannel}`] ||
    config[`${props.method}-${props.payChannel}`] ||
    config[props.payChannel];

  return (
    <div className="checkoutWallet">
      <div className="checkoutWallet__title">
        <p className="">{t("wallet_inst1")}</p>
      </div>
      <div className="checkoutWallet__content">
        <div className="checkoutWallet__content__qrContent">
          <p className="checkoutWallet__content__total">
            <FormatMoney type="total" />
          </p>

          <div className="checkoutWallet__content__qrContainer">
            {configData.qr_str && (
              <QRCode
                className="checkoutWallet__content__qr"
                style={{ filter: isQrExpired ? "blur(3px)" : "blur(0)" }}
                value={configData.qr_str}
                size={160}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"Q"}
                includeMargin={false}
                renderAs={"svg"}
                imageSettings={{
                  src: configData.qr_icon || "",
                  x: null,
                  y: null,
                  height: 24,
                  width: 24,
                  excavate: true,
                }}
              />
            )}
            {configData.qr_img && <img src={configData.qr_img} alt="" srcset="" style={{ width: "200px" }} />}
            {isQrExpired && <span className="checkoutWallet__content__qrNotice">QR Code Expired</span>}
            {configData.reference && (
              <div className="checkoutWallet__content__reference">
                <p>
                  <span className="checkoutWallet__content__referenceNo">{t("ref_no")}</span>
                  <span>{props.payData?.reference}</span>
                </p>
                <CopyButton text={props.payData?.reference} minWidth={100} />
              </div>
            )}
          </div>
        </div>
        <div className="checkoutWallet__content__inst">
          <div className="checkoutWallet__content__inst__titleContainer">
            <p className="checkoutWallet__content__inst__title">{t("wallet_inst2")}</p>
            {configData.logo && <img src={configData.logo} alt="" />}
          </div>
          <div className="checkoutWallet__content__inst__howToPay">
            <p className="checkoutWallet__content__inst__howToPay__title">{t("wallet_inst3")}</p>
            <div>
              <div className="">
                <p>{t("wallet_inst4")}</p>
                <p>{t("wallet_inst5")}</p>
                <p>{t("wallet_inst6")}</p>
                {configData.reference && (
                  <Warning>
                    <p className="checkoutWallet__content__warning">{t("wallet_inst7")}</p>
                  </Warning>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutWallet);
