const baseUrl = window.location.origin + "/public/static/images/latam-agents";

export const imageSetConfig = {
  PSE: {
    PGOne: {
      // TPaga
      "ALIANZA FIDUCIARIA": `${baseUrl}/alianza-fiduciaria.png`,
      BAN100: `${baseUrl}/ban100.svg`,
      "BANCAMIA S.A.": `${baseUrl}/bancamia.svg`,
      "BANCO AGRARIO": `${baseUrl}/banco-agrario.svg`,
      "BANCO AV VILLAS": `${baseUrl}/banco-av-villas.svg`,
      "BANCO BBVA COLOMBIA S.A.": `${baseUrl}/bbva.svg`,
      "BANCO CAJA SOCIAL": `${baseUrl}/banco-caja-social.png`,
      "BANCO COOPERATIVO COOPCENTRAL": `${baseUrl}/banco-coopcentral.svg`,
      "BANCO DAVIVIENDA": `${baseUrl}/banco-davivienda.svg`,
      "BANCO DE BOGOTA": `${baseUrl}/banco-bogota.svg`,
      "BANCO DE OCCIDENTE": `${baseUrl}/banco-occidente.svg`,
      "BANCO FALABELLA ": `${baseUrl}/banco-falabella.svg`,
      "BANCO FINANDINA S.A. BIC": `${baseUrl}/banco-finandina.svg`,
      "BANCO GNB SUDAMERIS": `${baseUrl}/banco-sudameris.svg`,
      "BANCO ITAU": `${baseUrl}/banco-itau.svg`,
      "BANCO J.P. MORGAN COLOMBIA S.A.": `${baseUrl}/jp-morgan.svg`,
      "BANCO MUNDO MUJER S.A.": `${baseUrl}/mudo-mujer.jpeg`,
      "BANCO PICHINCHA S.A.": `${baseUrl}/banco-pichincha.svg`,
      "BANCO POPULAR": `${baseUrl}/banco-popular.svg`,
      "BANCO SANTANDER COLOMBIA": `${baseUrl}/banco-santander.svg`,
      "BANCO SERFINANZA": `${baseUrl}/banco-serfinanza.svg`,
      "BANCO UNION antes GIROS": `${baseUrl}/banco-antes-giros.png`,
      BANCOLOMBIA: `${baseUrl}/bancolombia.svg`,
      "BANCOOMEVA S.A.": `${baseUrl}/bancoomeva.svg`,
      "CFA COOPERATIVA FINANCIERA": `${baseUrl}/cfa-cooperativa.png`,
      "CITIBANK ": `${baseUrl}/citibank.svg`,
      COLTEFINANCIERA: `${baseUrl}/contefinanciera.png`,
      "CONFIAR COOPERATIVA FINANCIERA": `${baseUrl}/confiar.svg`,
      "COOFINEP COOPERATIVA FINANCIERA": `${baseUrl}/coofinep.png`,
      COTRAFA: `${baseUrl}/contrafa.png`,
      "CREZCAMOS S.A. COMPAÑÍA DE FINANCIAMIENTO": `${baseUrl}/crezcamos.png`,
      DALE: `${baseUrl}/dale.svg`,
      DAVIPLATA: `${baseUrl}/daviplata.png`,
      IRIS: `${baseUrl}/iris.svg`,
      "LULO BANK": `${baseUrl}/lulo-bank.svg`,
      "MOVII S.A.": `${baseUrl}/movii.svg`,
      NEQUI: `${baseUrl}/nequi.svg`,
      RAPPIPAY: `${baseUrl}/rappi.svg`,
      "SCOTIABANK COLPATRIA": `${baseUrl}/scotiabank-colpatria.png`,
      UALÁ: `${baseUrl}/uala.png`,
    },
    TPaga: {
      "ALIANZA FIDUCIARIA": `${baseUrl}/alianza-fiduciaria.png`,
      BAN100: `${baseUrl}/ban100.svg`,
      "BANCAMIA S.A.": `${baseUrl}/bancamia.svg`,
      "BANCO AGRARIO": `${baseUrl}/banco-agrario.svg`,
      "BANCO AV VILLAS": `${baseUrl}/banco-av-villas.svg`,
      "BANCO BBVA COLOMBIA S.A.": `${baseUrl}/bbva.svg`,
      "BANCO CAJA SOCIAL": `${baseUrl}/banco-caja-social.png`,
      "BANCO COOPERATIVO COOPCENTRAL": `${baseUrl}/banco-coopcentral.svg`,
      "BANCO DAVIVIENDA": `${baseUrl}/banco-davivienda.svg`,
      "BANCO DE BOGOTA": `${baseUrl}/banco-bogota.svg`,
      "BANCO DE OCCIDENTE": `${baseUrl}/banco-occidente.svg`,
      "BANCO FALABELLA ": `${baseUrl}/banco-falabella.svg`,
      "BANCO FINANDINA S.A. BIC": `${baseUrl}/banco-finandina.svg`,
      "BANCO GNB SUDAMERIS": `${baseUrl}/banco-sudameris.svg`,
      "BANCO ITAU": `${baseUrl}/banco-itau.svg`,
      "BANCO J.P. MORGAN COLOMBIA S.A.": `${baseUrl}/jp-morgan.svg`,
      "BANCO MUNDO MUJER S.A.": `${baseUrl}/mudo-mujer.jpeg`,
      "BANCO PICHINCHA S.A.": `${baseUrl}/banco-pichincha.svg`,
      "BANCO POPULAR": `${baseUrl}/banco-popular.svg`,
      "BANCO SANTANDER COLOMBIA": `${baseUrl}/banco-santander.svg`,
      "BANCO SERFINANZA": `${baseUrl}/banco-serfinanza.svg`,
      "BANCO UNION antes GIROS": `${baseUrl}/banco-antes-giros.png`,
      BANCOLOMBIA: `${baseUrl}/bancolombia.svg`,
      "BANCOOMEVA S.A.": `${baseUrl}/bancoomeva.svg`,
      "CFA COOPERATIVA FINANCIERA": `${baseUrl}/cfa-cooperativa.png`,
      "CITIBANK ": `${baseUrl}/citibank.svg`,
      COLTEFINANCIERA: `${baseUrl}/contefinanciera.png`,
      "CONFIAR COOPERATIVA FINANCIERA": `${baseUrl}/confiar.svg`,
      "COOFINEP COOPERATIVA FINANCIERA": `${baseUrl}/coofinep.png`,
      COTRAFA: `${baseUrl}/contrafa.png`,
      "CREZCAMOS S.A. COMPAÑÍA DE FINANCIAMIENTO": `${baseUrl}/crezcamos.png`,
      DALE: `${baseUrl}/dale.svg`,
      DAVIPLATA: `${baseUrl}/daviplata.png`,
      IRIS: `${baseUrl}/iris.svg`,
      "LULO BANK": `${baseUrl}/lulo-bank.svg`,
      "MOVII S.A.": `${baseUrl}/movii.svg`,
      NEQUI: `${baseUrl}/nequi.svg`,
      RAPPIPAY: `${baseUrl}/rappi.svg`,
      "SCOTIABANK COLPATRIA": `${baseUrl}/scotiabank-colpatria.png`,
      UALÁ: `${baseUrl}/uala.png`,
    },
  },
  Transfiya: {
    Movii: {
      NEQUI: `${baseUrl}/nequi.svg`,
      "Banco Caja Social": `${baseUrl}/banco-caja-social.png`,
      DAVIPLATA: `${baseUrl}/daviplata.png`,
      Bancolombia: `${baseUrl}/bancolombia.svg`,
      DAVIVIENDA: `${baseUrl}/banco-davivienda.svg`,
      "Banco de Bogota": `${baseUrl}/banco-bogota.svg`,
      Dale: `${baseUrl}/dale.svg`,
      "Banco Itau": `${baseUrl}/banco-itau.svg`,
      "Banco Serfinanza": `${baseUrl}/banco-serfinanza.svg`,
      "Banco Cooperativo Coopcentral Digital": `${baseUrl}/banco-coopcentral.svg`,
      "Banco AV Villas": `${baseUrl}/banco-av-villas.svg`,
    },
  },
};
