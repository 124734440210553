import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Home.scss";
import { connect } from "react-redux";
import {
  setMethod,
  setChannel,
  setPayData,
  setTradeStatus,
  changeMethod,
  setPaypalCreditCard,
  setTradeInfo,
  getInfoById,
  setAlert,
} from "../../../redux/actions";
import Checkbox from "@material-ui/core/Checkbox";
import { isProduction, setOpenpayAcoount } from "../../../envs/env";
import PaymentMethod from "./PaymentMethod";
import EllipsisScroll from "../../comp/EllipsisScroll";
import TimeIcon from "../../../img/time-icon.svg";
// import LuxtakIcon from "../../../img/luxtak-logo.svg";
import Step1Act from "../../../img/step1_act.svg";
import Step1Pas from "../../../img/step1_pas.png";
import Step2Act from "../../../img/step2_act.svg";
import Step2Pas from "../../../img/step2_pas.png";
import Step3Pas from "../../../img/step3_pas.png";
import Step3Act from "../../../img/step3_act.svg";
import SortDown from "../../../img/sort_down.png";
import RightArrowBlack from "../../../img/right_arrow_black.png";
import Channel from "./Channel";
import Name from "../../form-input/Name";
import TaxId from "../../form-input/TaxId";
import Email from "../../form-input/Email";
import CreditCardExpTime from "../../form-input/CreditCardExpTime";
import CreditCardNumber from "../../form-input/CreditCardNumber";
import TitleWithDetails from "../../comp/TitleWithDetails";
import TimeRemaining from "../../comp/TimeRemaining";
import req from "../../../utils/axios";
import { useTranslation, Trans } from "react-i18next";
import { useMethodConfig, channelConfig, getProviderId } from "../../../utils/configurations";
import PrivacyPolicy from "./PrivacyPolicy";
import { jsonParse } from "../../../utils/jsonParse";
import State from "../../form-input/State";
import City from "../../form-input/City";
import PostalCode from "../../form-input/PostalCode";
import StreetAndNumber from "../../form-input/StreetAndNumber";
import * as val from "../../../utils/validations";
import { tokenize, authorize, queryKey, googleTokenize } from "../../../utils/tokanization";
import HiddenForm from "../../form-input/Openpay-creditcard/HiddenForm";
import { issuer, formatMethod, FormatMoney, hidedCardNo } from "../../../utils/formats";
import CardNo from "../../form-input/todito/CardNo";
import PinNo from "../../form-input/todito/PinNo";
import Installment from "../../form-input/Installment";
import PaypalCreditcard from "../../form-input/Paypal-creditcard/PaypalCreditcard";
import Source from "../../form-input/Source";
import Cep from "../../form-input/Boleto/Cep";
import PaypalButton from "./PaypalButton";
import FirstName from "../../form-input/FirstName";
import LastName from "../../form-input/LastName";
import SubmitForm from "../../form-input/SubmitPay/SubmitForm";
import PaygoOtp from "../../form-input/Paygo/PaygoOtp";
import { AsYouType } from "libphonenumber-js/mobile";
import PaymentMethodMobile from "./PaymentMethodMobile";
import Subscription from "../../comp/Subscription";
import { returnUrlAsParam } from "../../../utils/queryUrl";
import country2codes from "../../../utils/regionCodes";
import LottiAnimation from "../../comp/LottiAnimation";
import CrediMax from "../../form-input/CrediMax/CrediMax";
import MailImg from "../../../img/mail.png";
import AddressBox from "../../form-input/AddressBox";
import LocationImg from "../../../img/location-icon.png";
import VisaCard from "../../../img/visa_card.png";
import MasterCard from "../../../img/master_card.png";
import Amex from "../../../img/amex.png";
import PCI from "../../../img/pci_dss.png";
import Tooltip from "../../comp/Tooltip";
import Address from "../../form-input/Address/Address";
import getFromConfig, { isAddressNeeded } from "../../form-input/Config/formConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountNumber from "../../form-input/Localpayment/AccountNumber";
import MobilePhone from "../../form-input/MobilePhone";
import BankList from "../../form-input/Localpayment/BankList";
import VoucherNumber from "../../form-input/Neosurf/VoucherNumber";
import Fees from "../../comp/Fees";
import { handleErrorGlobal } from "../../../utils/toast";
import WarningComp from "../../comp/Warning";
import useFormData from "./useFormData";
import MpCreditCard from "../../form-input/Mp-creditcard/MpCreditCard";
import Country from "../../form-input/EU/Country";
import PaypalButtonNative from "./PaypalButtonNative";
import KushkiOtp from "../../form-input/Kushki/KushkiOtp";
import BankListPopUp from "../../form-input/OpenFinance/BankListPopUp";

var Scroll = require("react-scroll");
var scroller = Scroll.scroller;
var classNames = require("classnames");

function Home(props) {
  const handleError = (err, notice_code, notice_text) => {
    handleErrorGlobal({
      setIsLoading: setIsloading,
      err: err,
      notice_code: notice_code,
      notice_text: notice_text,
      defaultErrCode: "fail_change_method",
    });
  };

  const region = props?.tradeInfo?.app_info?.region;

  const { t } = useTranslation();
  let history = useHistory();
  const methodConfig = useMethodConfig();

  const [form, setForm] = useFormData();

  const [focusOnInput, setFocusOnInput] = useState(false);
  const [showPolicy, setShowPolicy] = useState({ visable: false, isPrivacyPolicy: false });
  const [iframePopUp, setIframePopUp] = useState("");
  // const [selectNotice, setSelectNotice] = useState({ state: false, notice: "", callBack: null });
  const [dropDownState, setDropDownState] = useState(true);
  const [footerDropDownState, setFooterDropDownState] = useState(true);

  // For Payment methods
  const paypalChildRef = useRef();
  const paypalChildRefMobile = useRef();
  const crediMaxChildRef = useRef();
  const mpChildRef = useRef();
  const [isMpReady, setIsMpReady] = useState(false);
  const [submitData, setSubmitData] = useState("");
  const [paygoOtp, setPaygoOtp] = useState(false);
  const [kushkiOtp, setKushkiOtp] = useState(false);
  //////////
  const providerId = getProviderId();

  var [isLoading, setIsloading] = useState(false);
  var [isDisabled, setIsDisabled] = useState(false);

  const formConfig = getFromConfig({
    region: props.tradeInfo?.app_info?.region,
    method: props.method,
    channel: props.payChannel,
    isAddressSame: form.isSameAddress,
    providerId,
  });
  const isFillingRequired = formConfig?.length > 0 || props.tradeInfo?.is_original_source_required;

  useEffect(() => {
    if (isDisabled === true) {
      props.setAlert({
        state: true,
        message: t("order_timeout"),
        callback: () => {
          props.returnUrl && window.location.replace(props.returnUrl);
        },
      });
    }
  }, [isDisabled]);

  const disablePay = () => {
    setIsDisabled(true);
  };

  const closePolicy = () => {
    setShowPolicy({ visable: false, isPrivacyPolicy: false });
  };

  const getValidityResult = ({ isSetForm = true } = {}) => {
    let validity = false;
    if (formConfig) {
      validity = formConfig?.reduce?.((result, item) => {
        const itemValidity = !isSetForm
          ? form?.[item].valid && form?.[item].checked
          : typeof form?.[item]?.validityFunction === "function"
          ? form?.[item]?.validityFunction({
              form: form,
              setForm: setForm,
              region: props.tradeInfo?.app_info?.region,
              isGlobal: form.isGlobal,
              bank: props.channel?.data?.[0]?.bank,
            })
          : false;
        // console.log(item + " ---- " + itemValidity);
        return result && itemValidity;
      }, true);
    }

    if (props.tradeInfo?.is_original_source_required) {
      validity =
        (!isSetForm ? form.source.checked && form.source.valid : val.checkSource({ form, setForm })) && validity;
    }

    // console.log("validity >>>>> ", validity);
    return validity;
  };

  const scrollToFilling = () => {
    scroller.scrollTo("filling_detail", {
      duration: 500,
      smooth: true,
      offset: -100, // Scrolls to element + 50 pixels down the page
    });
  };
  const checkIsAllValid = () => {
    let validityResult = getValidityResult();

    if (
      (props.method === "CreditCard" || props.method === "RecurringCreditCard" || props.method === "PlayersBankCard") &&
      props.payChannel === "Paypal"
    ) {
      props.setPaypalCreditCard({ type: "keepPrev", key: "cardName", value: { checked: true } });
      props.setPaypalCreditCard({ type: "keepPrev", key: "cardNumber", value: { checked: true } });
      props.setPaypalCreditCard({ type: "keepPrev", key: "expDate", value: { checked: true } });
      props.setPaypalCreditCard({ type: "keepPrev", key: "cvv", value: { checked: true } });
      let paypalValidity =
        validityResult &&
        props.paypalCreditCard.authorizationToken &&
        props.paypalCreditCard.deviceData &&
        props.paypalCreditCard.cardName.valid &&
        props.paypalCreditCard.cardName.checked &&
        props.paypalCreditCard.cardNumber.valid &&
        props.paypalCreditCard.cardNumber.checked &&
        props.paypalCreditCard.expDate.valid &&
        props.paypalCreditCard.expDate.checked &&
        props.paypalCreditCard.cvv.valid &&
        props.paypalCreditCard.cvv.checked;
      !paypalValidity && scrollToFilling();
      return paypalValidity;
    } else {
      !validityResult && scrollToFilling();
      return validityResult;
    }
  };

  const isPeru = () => {
    return props.method === "Cash" && props.payChannel === "SafetyPay" && props.tradeInfo?.app_info?.region === "PER";
  };

  const checkIsAllSelected = () => {
    if (props.method) {
      if (channelConfig(props.method, props.payChannel)) {
        if (props.channel?.data) {
          return true;
        } else {
          props.setAlert({ state: true, message: t("select_notice") });
          return false;
        }
      } else {
        return true;
      }
    } else {
      props.setAlert({ state: true, message: t("select_notice") });
      return false;
    }
  };

  const doPay = (reqData, extraData) => {
    setIsloading(true);
    req
      .post(`/api/trade/pay?prepay_id=${props.prepayId}`, reqData)
      .then((res) => {
        Object.keys(window?.localStorage || {}).forEach((item) => {
          if (item.substring(0, 3) === "PS_") {
            localStorage.removeItem(item);
          }
        });
        localStorage?.setItem("PS_" + props.prepayId + "channel", JSON.stringify(props.channel));
        localStorage?.setItem("PS_" + props.prepayId + "method", JSON.stringify(props.method));
        if (res.data.code === "10000") {
          localStorage?.setItem("PS_" + props.prepayId + "payData", JSON.stringify(res.data.data));
          localStorage?.setItem("__frm_name", form.name.value);
          localStorage?.setItem("__frm_id_type", form.idType);
          localStorage?.setItem("__frm_tax_id", form.taxId.value);
          localStorage?.setItem("__frm_email", form.email.value);
          localStorage?.setItem("__frm_phone", form.phone.value);
          localStorage?.setItem("__frm_cep", form.boleto_cep.value);

          let tradeInfo = {
            ...props.tradeInfo,
            user: {
              ...props.tradeInfo?.user,
              identification: { type: form.idType, number: form.taxId.value },
              name: form.name.value,
            },
          };
          props.setTradeInfo(tradeInfo);

          if ((props.method === "PSE" || props.method === "Efecty") && props.payChannel === "Payty") {
            let queryObj = res.data?.data?.form_date && jsonParse(res.data?.data?.form_date);
            let url =
              res.data?.data?.pay_url +
              "?" +
              Object.keys(queryObj)
                ?.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(queryObj[k])}`)
                .join("&");

            url && window.location.replace(url);
          } else if (
            (props.method === "Khipu" && props.payChannel === "Khipu") ||
            (props.method === "Khipu" && props.payChannel === "PGOne") ||
            (props.method === "Wallet" &&
              (props.payChannel === "PayNow" ||
                (props.payChannel === "GrabPay" && providerId === "1067") ||
                (props.payChannel === "GCash" && providerId === "1152") ||
                props.payChannel === "MADA" ||
                props.payChannel === "KNet" ||
                props.payChannel === "Skrill" ||
                props.payChannel === "Paysafecard" ||
                props.payChannel === "Fawry" ||
                props.payChannel === "YooMoney" ||
                props.payChannel === "Chek" ||
                props.payChannel === "Daviplata" ||
                props.payChannel === "Rappipay" ||
                props.payChannel === "Dale" ||
                props.payChannel === "Movii" ||
                props.payChannel === "Nequi" ||
                props.payChannel === "ClaroPay" ||
                props.payChannel === "NaverPay" ||
                props.payChannel === "Papara")) ||
            (props.method === "CreditCard" && props.payChannel === "SHAREitPay") ||
            (props.method === "NetBanking" && props.payChannel === "SHAREitPay") ||
            (props.method === "UPI" && (props.payChannel === "SHAREitPay" || props.payChannel === "Tazapay")) ||
            ((props.method === "PagoEfectivo" || props.method === "BankTransfer" || props.method === "Cash") &&
              props.payChannel === "PagoEfectivo") ||
            (props.method === "CreditCard" && props.payChannel === "Monnet") ||
            (props.method === "BankTransfer" && props.payChannel === "Multicaja") ||
            (props.method === "CreditCard" && props.payChannel === "Payletter") ||
            (props.method === "BankTransfer" && props.payChannel === "Payletter") ||
            (props.method === "VirtualAccount" && props.payChannel === "Payletter") ||
            (props.method === "PSE" && props.payChannel === "PayValida") ||
            (props.method === "CreditCard" && props.payChannel === "Payku") ||
            ((props.method === "Pago46" || props.method === "WebPay") && props.payChannel === "Payku") ||
            (props.method === "BankTransfer" && props.payChannel === "ETpay") ||
            (props.method === "BankTransfer" && props.payChannel === "PGOne" && region === "CHL") ||
            ((props.method === "Sofort" ||
              props.method === "Neosurf" ||
              props.method === "Bancontact" ||
              props.method === "EPS" ||
              props.method === "Giropay" ||
              props.method === "iDeal" ||
              props.method === "MyBank" ||
              props.method === "P24" ||
              props.method === "Trustly") &&
              props.payChannel === "Emerchantpay") ||
            (props.method === "SberBank" && props.payChannel === "YooMoney") ||
            (props.method === "Giropay" && props.payChannel === "PPRO") ||
            ((props.method === "Efecty" || props.method === "PSE") && props.payChannel === "PaytyV2") ||
            (props.method === "Trustly" && props.payChannel === "PPRO") ||
            (props.method === "OpenFinance" && props.payChannel === "Mercadopago") ||
            (props.method === "OpenFinance" && props.payChannel === "Iniciador") ||
            (props.method === "Paysafecard" && props.payChannel === "PPRO") ||
            (props.method === "Payconiq" && props.payChannel === "PPRO") ||
            (props.method === "PSE" && (props.payChannel === "ACH" || props.payChannel === "PGOne")) ||
            ((props.method === "BankTransfer" || props.method === "Cash") &&
              props.payChannel === "PGOne" &&
              region === "ECU")
          ) {
            res.data?.data?.pay_url && window.location.replace(res.data?.data?.pay_url);
          } else if (props.method === "CreditCard" && props.payChannel === "PGOne" && region === "RUS") {
            if (res.data?.data?.pay_url) {
              window.location.replace(res.data?.data?.pay_url);
            } else {
              props.setTradeStatus({ id: "process", query: true });
              history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            }
          } else if (
            props.method === "Wallet" &&
            (props.payChannel === "AlipayCN" ||
              props.payChannel === "AlipayHK" ||
              (props.payChannel === "GCash" &&
                (providerId === "1075" || providerId === "1167" || providerId === "1161")) ||
              props.payChannel === "KakaoPayAlipay" ||
              props.payChannel === "TNG" ||
              props.payChannel === "TrueMoney" ||
              props.payChannel === "TPaga" ||
              props.payChannel === "RabbitLinePay")
          ) {
            if (val.isMobile()) {
              if (providerId === "1167") {
                res.data?.data?.pay_url && window.location.replace(res.data?.data?.pay_url);
              } else {
                res.data?.data?.wallet_url && window.location.replace(res.data?.data?.wallet_url);
              }
            } else {
              props.setPayData(res.data.data);
              props.setTradeStatus({ id: "order" });
              history.replace(`/checkout?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            }
          } else if (
            ((props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay") ||
            (props.method === "SPEI" &&
              (props.payChannel === "OpenPay" ||
                props.payChannel === "STP" ||
                props.payChannel === "Banwire" ||
                props.payChannel === "PGOne")) ||
            (props.method === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay")) ||
            (props.method === "OXXO" &&
              (props.payChannel === "Banwire" ||
                props.payChannel === "CardPay" ||
                props.payChannel === "Bamboo" ||
                props.payChannel === "Localpayment")) ||
            ((props.method === "Boleto" || props.method === "BoletoRapido") && props.payChannel === "BS2") ||
            (props.method === "Boleto" && props.payChannel === "StarkBank") ||
            (props.method === "Boleto" && props.payChannel === "Mercadopago") ||
            (props.method === "Boleto" && props.payChannel === "CHANNEL_GROUP_ROUTER") ||
            (props.method === "PIX" &&
              (props.payChannel === "BS2" ||
                props.payChannel === "Bradesco" ||
                props.payChannel === "Rendimento" ||
                props.payChannel === "Nupay" ||
                props.payChannel === "Genial" ||
                props.payChannel === "StarkBank" ||
                props.payChannel === "CHANNEL_GROUP_ROUTER" ||
                props.payChannel === "Mercadopago" ||
                props.payChannel === "StarkBankTransferSmile" ||
                props.payChannel === "BTG" ||
                props.payChannel === "Fastcash" ||
                props.payChannel === "Pagsmile" ||
                props.payChannel === "Delbank" ||
                props.payChannel === "Starpago")) ||
            (props.method === "DepositExpress" && props.payChannel === "Levpay") ||
            (props.method === "OTC" && props.payChannel === "Xendit") ||
            (props.method === "Cash" &&
              (props.payChannel === "PayCash" || (props.payChannel === "PGOne" && region === "MEX"))) ||
            (props.method === "OXXOPay" && (props.payChannel === "Conekta" || props.payChannel === "PGOne")) ||
            (props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
            (props.method === "Wallet" &&
              (props.payChannel === "Mach" ||
                props.payChannel === "Vita" ||
                props.payChannel === "AME" ||
                props.payChannel === "Fpay" ||
                props.payChannel === "FawryPay" ||
                props.payChannel === "Tupay" ||
                props.payChannel === "ShopeePay")) ||
            (props.method === "CoDi" && (props.payChannel === "STP" || props.payChannel === "PGOne")) ||
            (props.method === "KLAP" && props.payChannel === "Multicaja") ||
            (props.method === "Cash" && props.payChannel === "Multicaja") ||
            ((props.method === "SuRed" ||
              props.method === "GANA" ||
              props.method === "Bancolombia" ||
              props.method === "BCR" ||
              props.method === "BNCR") &&
              props.payChannel === "PayValida") ||
            (props.method === "BNCR" && props.payChannel === "PayCash") ||
            (props.method === "PlayersBankPIX" && props.payChannel === "BS2") ||
            ((props.method === "BankTransfer" ||
              props.method === "PagoFacil" ||
              props.method === "Rapipago" ||
              props.method === "Cash" ||
              props.method === "Servipag") &&
              props.payChannel === "Localpayment") ||
            (props.method === "PagoFacil" && props.payChannel === "PagoFacil") ||
            (props.method === "Transfiya" && props.payChannel === "Movii") ||
            (props.method === "Crypto" && props.payChannel === "PGOne") ||
            (props.method === "Binance" && props.payChannel === "PGOne") ||
            (props.method === "Multibanco" && props.payChannel === "PPRO") ||
            (props.method === "Cash" && props.payChannel === "FawryPay") ||
            (props.method === "Rapipago" && props.payChannel === "Rapipago") ||
            (props.method === "SBP" && props.payChannel === "PGOne") ||
            (props.method === "SberPay" && props.payChannel === "PGOne") ||
            (props.method === "QRCode" && props.payChannel === "PGOne" && region === "PER")
          ) {
            props.setPayData(res.data.data);
            props.setTradeStatus({ id: "order" });
            history.replace(`/checkout?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
          } else if (
            ((props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet") ||
            ((props.method === "BankTransfer" || props.method === "Cash") &&
              (props.payChannel === "KASHIO" || (props.payChannel === "PGOne" && region === "PER")))
          ) {
            if (props.tradeInfo?.app_info?.region === "PER" && res.data.data?.reference) {
              props.setPayData(res.data.data);
              props.setTradeStatus({ id: "order" });
              history.replace(`/checkout?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            } else {
              res.data?.data?.pay_url && window.location.replace(res.data?.data?.pay_url);
            }
          } else if (
            (props.method === "Wallet" &&
              (props.payChannel === "PicPay" ||
                props.payChannel === "DANA" ||
                props.payChannel === "BPI" ||
                props.payChannel === "QIWI" ||
                props.payChannel === "BOOST" ||
                props.payChannel === "AstroPay" ||
                props.payChannel === "Mercadopago" ||
                props.payChannel === "TOSS" ||
                props.payChannel === "PAYCO" ||
                props.payChannel === "SamsungPay" ||
                (props.payChannel === "GrabPay" && providerId === "1141") ||
                props.payChannel === "OVO" ||
                props.payChannel === "KakaoPay")) ||
            (props.method === "AlipayPlus" && props.payChannel === "AlipayPlus")
          ) {
            res.data?.data?.wallet_url && window.location.replace(res.data?.data?.wallet_url);
          } else if (
            props.method === "Lottery" &&
            (props.payChannel === "Caixa" || props.payChannel === "Mercadopago")
          ) {
            props.setPayData({ ...res.data.data, userCPF: form.taxId.value });
            props.setTradeStatus({ id: "order" });
            history.replace(`/checkout?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
          } else if (
            (props.method === "CreditCard" &&
              props.payChannel === "OpenPay" &&
              (props.tradeInfo?.app_info?.region === "MEX" || props.tradeInfo?.app_info?.region === "EUP")) ||
            (props.method === "CreditCard" && props.payChannel === "PGOne" && region === "MEX")
          ) {
            if (!isProduction()) {
              props.setTradeStatus({ id: "process", query: true });
              history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            } else {
              res.data?.data?.check_url && window.location.replace(res.data?.data?.check_url);
            }
          } else if (props.method === "CreditCard" && props.payChannel === "CardPay") {
            let joinStr = "?";
            if (res.data?.data?.check_url.indexOf("?") >= 0) {
              joinStr = "&";
            }
            window.location.replace(
              res.data?.data?.check_url + joinStr + "return_url=" + encodeURIComponent(props.returnUrl)
            );
          } else if (
            (props.method === "AlfaClick" ||
              props.method === "Qiwi" ||
              props.method === "WebMoney" ||
              props.method === "YandexMoney" ||
              props.method === "UnionPay") &&
            props.payChannel === "Payssion"
          ) {
            let joinStr = "?";
            if (res.data?.data?.pay_url.indexOf("?") >= 0) {
              joinStr = "&";
            }
            window.location.replace(
              res.data?.data?.pay_url + joinStr + "return_url=" + encodeURIComponent(props.returnUrl)
            );
          } else if (
            ((props.method === "Wallet" || props.method === "ToditoCash") && props.payChannel === "Todito") ||
            ((props.method === "CreditCard" ||
              props.method === "RecurringCreditCard" ||
              props.method === "PlayersBankCard") &&
              props.payChannel === "Paypal") ||
            (props.method === "Wallet" && props.payChannel === "Paypal") ||
            (props.method === "CreditCard" && props.payChannel === "Xendit") ||
            (props.method === "CreditCard" && props.payChannel === "Localpayment") ||
            (props.method === "DebitCard" && props.payChannel === "Localpayment") ||
            (props.method === "CreditCard" && props.payChannel === "Movii") ||
            (props.method === "CreditCard" && props.payChannel === "Izipay") ||
            (props.method === "CreditCard" && props.payChannel === "IzipaySmileone") ||
            (props.method === "CreditCard" &&
              props.payChannel === "OpenPay" &&
              props.tradeInfo?.app_info?.region === "PER") ||
            (props.method === "CreditCard" && props.payChannel === "Multicaja") ||
            (props.method === "CreditCard" && props.payChannel === "Mercadopago") ||
            (props.method === "CreditCard" && props.payChannel === "Bamboo") ||
            (props.method === "CreditCard" && props.payChannel === "Kushki")
          ) {
            //window.location.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            props.setTradeStatus({ id: "process", query: true });
            history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
          } else if (props.method === "CreditCard" && props.payChannel === "E-Comprocessing") {
            if (res.data?.data?.pay_url) {
              window.location.replace(res.data?.data?.pay_url);
            } else if (res.data?.data?.check_url) {
              let data = {
                ...res.data?.data,
                form_date: res.data?.data?.form_data,
                pay_url: res.data?.data?.check_url,
              };
              setSubmitData(data);
            } else {
              props.setTradeStatus({ id: "process", query: true });
              history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            }
          } else if (props.method === "CreditCard" && props.payChannel === "QIWI") {
            if (res.data?.data?.form_data) {
              let data = {
                ...res.data?.data,
                form_date: res.data?.data?.form_data,
              };
              setSubmitData(data);
            } else {
              res.data?.data?.pay_url && window.location.replace(res.data?.data?.pay_url);
            }
          } else if (props.method === "CreditCard" && props.payChannel === "CrediMax") {
            if (res.data?.data?.check_url) {
              let data = {
                ...res.data?.data,
                form_date: res.data?.data?.form_data,
                pay_url: res.data?.data?.check_url,
              };
              setSubmitData(data);
            } else {
              props.setTradeStatus({ id: "process", query: true });
              history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            }
          } else if (
            ((props.method === "BankTransfer" || props.method === "OTC") && props.payChannel === "HappyPay") ||
            (props.method === "Wallet" && props.payChannel === "LINEPay")
          ) {
            if (res.data?.data?.form_data) {
              let data = {
                ...res.data?.data,
                form_date: res.data?.data?.form_data,
              };
              setSubmitData(data);
            }
          } else if (
            props.method === "Wallet" &&
            (props.payChannel === "GcashXendit" || props.payChannel === "Paymaya")
          ) {
            if (val.isMobile()) {
              window.location.replace(res.data?.data?.mobile_url);
            } else {
              window.location.replace(res.data?.data?.desktop_url);
            }
          } else if (props.method === "GCash" && props.payChannel === "SHAREitPay") {
            const prefix = res?.data?.data?.pay_url.indexOf("?") >= 0 ? "&" : "?";
            window.location.replace(
              res.data?.data?.pay_url + prefix + "return_url=" + encodeURIComponent(props.returnUrl)
            );
          } else if (
            (props.method === "PiPay" && props.payChannel === "PiPay") ||
            (props.method === "Wallet" && props.payChannel === "Eprepag") ||
            (props.method === "Wallet" && props.payChannel === "PaypalXCG")
          ) {
            setSubmitData(res.data?.data);
          } else if (props.method === "PayGo" && props.payChannel === "EDEE") {
            setPaygoOtp(true);
            setIsloading(false);
          } else if (props.method === "CreditCard" && props.payChannel === "Adiq") {
            if (res.data?.data?.check_url && res.data?.data?.form_data && !extraData?.challenge) {
              const formdata = jsonParse(res.data?.data?.form_data || "{}");
              // console.log(
              //   `res.data?.data?.check_url: ${res.data?.data?.check_url} formdata.pareq: ${formdata.pareq} formdata.authenticationTransactionId: ${formdata.authenticationTransactionId}`
              // );
              extraData?.threeDs?.InitChallenge(
                res.data?.data?.check_url,
                formdata?.pareq,
                formdata?.authenticationTransactionId
              );
              setIsloading(false);
            } else {
              props.setTradeStatus({ id: "process", query: true });
              history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
            }
          }
        } else {
          localStorage?.setItem("PS_" + props.prepayId + "payData", JSON.stringify(res.data));
          props.setPayData(res.data);
          if (res.data?.code === "90003") {
            // this code is not same as we get using query endpoint, so dont use tradaStatusCode function
            props.setTradeStatus({
              id: "process",
              text: "risk_control", // i18n id
              notice: "", // i18n id
            });
          } else if (res.data.code === "40002" && res.data?.sub_code === "timed-out") {
            // this code is not same as we get using query endpoint, so dont use tradaStatusCode function
            props.setTradeStatus({
              id: "other",
              text: "40101", // i18n id
              notice: "order_timeout", // i18n id
            });
          } else {
            props.setTradeStatus({ id: "fail" });
          }
          history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
        }
      })
      .catch((err) => {
        if (err && !err.response) {
          handleError(err, "network_err");
        } else {
          handleError(err);
        }
      });
  };

  const isReadyToPay = () => {
    return checkIsAllSelected() && checkIsAllValid() && form.isPolicyAccepted;
  };

  const payRequest = (data) => {
    if (isReadyToPay()) {
      const commonData = {
        method: props.method,
        channel: props.payChannel,
        username: form.name.value?.trim(),
        email: form.email.value?.toLowerCase(),
        phone: form.phone.value?.replace(/[^\d]/g, "")?.length > 5 ? form.phone.value?.replace(/[^\d]/g, "") : "",
        amount: props.paymentInfo?.pay_amount,
        currency: props.paymentInfo?.pay_currency,
        out_trade_no: props.tradeInfo?.out_trade_no,
        return_url: props.returnUrl,
        prev_url: document.referrer,
        pay_region: props.tradeInfo?.app_info?.region,
      };
      let taxIdData = {
        id: {
          type: form.idType,
          number: form.taxId.value.replace(/[-/.]/g, ""),
        },
      };
      if (props.tradeInfo?.is_original_source_required) {
        commonData.original_source = form.source.value;
      }
      if (isAddressNeeded(props.method, props.payChannel, props.tradeInfo?.app_info?.region)) {
        const billingAddress = {
          address: {
            postal_code: form.bill_postalCode.value,
            country: form.bill_country.value,
            country_code: country2codes.find((item) => item.country_name === form.bill_country.value)?.alpha2_code,
            state: form.bill_state.value,
            city: form.bill_city.value,
            street: form.bill_street.value,
            street_number: form.bill_no.value,
          },
        };
        commonData.billing = { ...billingAddress };
        commonData.shipping = form.isSameAddress
          ? { ...billingAddress }
          : {
              address: {
                postal_code: form.delivery_postalCode.value,
                country: form.delivery_country.value,
                country_code: country2codes.find((item) => item.country_name === form.delivery_country.value)
                  ?.alpha2_code,
                state: form.delivery_state.value,
                city: form.delivery_city.value,
                street: form.delivery_street.value,
                street_number: form.delivery_no.value,
              },
            };
      }

      if (
        (props.method === "Khipu" && props.payChannel === "Khipu") ||
        (props.method === "Khipu" && props.payChannel === "PGOne")
      ) {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: props.channel?.data[0]?.bank_id,
        };
        doPay(reqData);
      } else if (
        (props.method === "Efecty" && props.payChannel === "Payty") ||
        (props.method === "PSE" && props.payChannel === "Payty") ||
        ((props.method === "Efecty" || props.method === "PSE") && props.payChannel === "PaytyV2") ||
        (props.method === "PIX" &&
          (props.payChannel === "BS2" ||
            props.payChannel === "Bradesco" ||
            props.payChannel === "Rendimento" ||
            props.payChannel === "Nupay" ||
            props.payChannel === "Genial" ||
            props.payChannel === "StarkBank" ||
            props.payChannel === "CHANNEL_GROUP_ROUTER" ||
            props.payChannel === "Mercadopago" ||
            props.payChannel === "StarkBankTransferSmile" ||
            props.payChannel === "BTG" ||
            props.payChannel === "Fastcash" ||
            props.payChannel === "Pagsmile" ||
            props.payChannel === "Delbank" ||
            props.payChannel === "Starpago")) ||
        (props.method === "Wallet" &&
          (props.payChannel === "PicPay" ||
            props.payChannel === "Vita" ||
            props.payChannel === "AME" ||
            props.payChannel === "TPaga" ||
            props.payChannel === "Chek" ||
            props.payChannel === "Mach" ||
            props.payChannel === "ClaroPay" ||
            props.payChannel === "Mercadopago")) ||
        ((props.method === "PagoEfectivo" || props.method === "BankTransfer" || props.method === "Cash") &&
          props.payChannel === "PagoEfectivo") ||
        (props.method === "KLAP" && props.payChannel === "Multicaja") ||
        (props.method === "Cash" && props.payChannel === "Multicaja") ||
        (props.method === "BankTransfer" && props.payChannel === "Multicaja") ||
        (props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
        ((props.method === "Pago46" || props.method === "WebPay") && props.payChannel === "Payku") ||
        (props.method === "BankTransfer" && props.payChannel === "ETpay") ||
        (props.method === "SuRed" && props.payChannel === "PayValida") ||
        (props.method === "GANA" && props.payChannel === "PayValida") ||
        (props.method === "Bancolombia" && props.payChannel === "PayValida") ||
        (props.method === "PlayersBankPIX" && props.payChannel === "BS2") ||
        ((props.method === "PagoFacil" || props.method === "Servipag" || props.method === "Rapipago") &&
          props.payChannel === "Localpayment") ||
        (props.method === "PagoFacil" && props.payChannel === "PagoFacil") ||
        (props.method === "SPEI" &&
          (props.payChannel === "OpenPay" ||
            props.payChannel === "STP" ||
            props.payChannel === "Banwire" ||
            props.payChannel === "PGOne")) ||
        (props.method === "Lottery" && (props.payChannel === "Caixa" || props.payChannel === "Mercadopago")) ||
        (props.method === "Rapipago" && props.payChannel === "Rapipago")
      ) {
        let reqData = {
          ...commonData,
          ...taxIdData,
        };
        doPay(reqData);
      } else if ((props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay") {
        let reqData = {
          ...commonData,
          language_code: props.channel?.data[0]?.language_code,
          bank: props.channel?.data[0]?.bank_id,
          payment_type: props.method === "BankTransfer" ? "1" : "2",
        };
        if (props.method === "BankTransfer") {
          reqData = { ...reqData, ...taxIdData };
        }
        doPay(reqData);
      } else if (props.method === "DepositExpress" && props.payChannel === "Levpay") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: props.channel?.data[0]?.bank,
        };
        doPay(reqData);
      } else if (
        (props.method === "CreditCard" && props.payChannel === "OpenPay") ||
        (props.method === "CreditCard" && props.payChannel === "PGOne" && region === "MEX")
      ) {
        if (props.loadedLibs["CreditCard-OpenPay"] || props.loadedLibs["CreditCard-PGOne"]) {
          setIsloading(true);
          googleTokenize()
            .then((captchaToken) => {
              setOpenpayAcoount(region);
              const deviceSessionId = window.OpenPay?.deviceData?.setup("payment-form", "deviceIdHiddenFieldName");
              tokenize(
                props.prepayId,
                issuer,
                form,
                (token) => {
                  // callback for success
                  let reqData = {
                    ...commonData,
                    address: {
                      zip_code: form.postalCode.value,
                    },
                    extra_token: deviceSessionId,
                    token: token,
                    card_no: hidedCardNo(form.cardNumber.value),
                    fingerprint: window.black_box_tongdun,
                    forter_token_cookie: window.black_box_forter,
                    captcha: captchaToken,
                  };
                  doPay(reqData);
                },
                (err) => {
                  // callback for fail
                  handleError(err);
                }
              );
              // window.OpenPay.token.extractFormAndCreate(
              //   "payment-form",
              //   (response) => {
              //     //console.log(response);
              //     // in case of success
              //     let reqData = {
              //       ...commonData,
              //       extra_token: deviceSessionId,
              //       token: response.data?.id,
              //       card_no: hidedCardNo(form.cardNumber.value),
              //       fingerprint: window.black_box,
              //       forter_token_cookie: window.black_box_forter,
              //     };
              //     doPay(reqData);
              //   },
              //   (response) => {
              //     // in case of error
              //     setIsloading(false);
              //     let desc = response.data.description !== undefined ? response.data.description : response.message;
              //     //console.log(JSON.stringify(response));
              //     alert("ERROR [" + response.status + "] " + desc);
              //   }
              // );
            })
            .catch((err) => {
              handleError(`Google captcha error: ${err}`);
            });
        } else {
          handleError("OpenPay libs are not loaded successfully :(");
        }
      } else if (props.method === "CreditCard" && props.payChannel === "E-Comprocessing") {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          googleTokenize()
            .then((captchaToken) => {
              tokenize(
                props.prepayId,
                issuer,
                {
                  ...form,
                  name: { ...form.name, value: form.firstName.value.trim() + " " + form.lastName.value.trim() },
                },
                (token) => {
                  // callback for success
                  let reqData = {
                    ...commonData,
                    username: form.firstName.value.trim() + " " + form.lastName.value.trim(),
                    region: country2codes.find((item) => item.country_name === form.bill_country.value),
                    card_no: hidedCardNo(form.cardNumber.value),
                    issuer: issuer,
                    token: token, // add luxtak_token : token
                    fingerprint: window.black_box_tongdun,
                    forter_token_cookie: window.black_box_forter,
                    captcha: captchaToken,
                    device_data: JSON.stringify({
                      accept_header: "application/json",
                      java_enabled: "true",
                      language: String(window.navigator.language),
                      color_depth: [1, 4, 8, 15, 16, 24, 32, 48].includes(window.screen.colorDepth)
                        ? String(window.screen.colorDepth)
                        : String(
                            [1, 4, 8, 15, 16, 24, 32, 48].reduce((result, num) => {
                              return window.screen.colorDepth - num > 0 &&
                                window.screen.colorDepth - num < window.screen.colorDepth - result
                                ? num
                                : result;
                            }, 1)
                          ),
                      screen_height: String(window.screen.height),
                      screen_width: String(window.screen.width),
                      challenge_window_size: "full_screen",
                      time_zone_offset: String(new Date().getTimezoneOffset()),
                      user_agent: window.navigator.userAgent,
                    }),
                  };
                  doPay(reqData);
                },
                (err) => {
                  // callback for fail
                  handleError(err);
                }
              );
            })
            .catch((err) => {
              handleError(`Google captcha error: ${err}`);
            });
        } else {
          handleError("E-Comprocessing libs are not loaded successfully :(");
        }
      } else if (
        (props.method === "CreditCard" && props.payChannel === "CardPay") ||
        (props.method === "CreditCard" && props.payChannel === "Movii") ||
        (props.method === "CreditCard" && props.payChannel === "Izipay") ||
        (props.method === "CreditCard" && props.payChannel === "IzipaySmileone") ||
        (props.method === "CreditCard" && props.payChannel === "Multicaja") ||
        (props.method === "CreditCard" && props.payChannel === "Bamboo") ||
        (props.method === "CreditCard" && props.payChannel === "PGOne" && region === "RUS")
      ) {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          googleTokenize()
            .then((captchaToken) => {
              tokenize(
                props.prepayId,
                issuer,
                form,
                (token) => {
                  // callback for success
                  let reqData = {
                    ...commonData,
                    card_no: hidedCardNo(form.cardNumber.value),
                    issuer: issuer,
                    token: token,
                    fingerprint: window.black_box_tongdun,
                    forter_token_cookie: window.black_box_forter,
                    captcha: captchaToken,
                  };
                  doPay(reqData);
                },
                (err) => {
                  // callback for fail
                  handleError(err);
                }
              );
            })
            .catch((err) => {
              handleError(`Google captcha error: ${err}`);
            });
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else if (props.method === "CreditCard" && props.payChannel === "Kushki") {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          googleTokenize()
            .then((captchaToken) => {
              tokenize(
                props.prepayId,
                issuer,
                form,
                (token) => {
                  const kushki = new window.Kushki({
                    merchantId: isProduction()
                      ? "5dfd8c2112e540c091405e7be6ce4851"
                      : "6f3651ee30c040b8854d7f6b22ed8a83", // Your public merchant id
                    inTestEnvironment: !isProduction(),
                  });
                  const kushkiData = {
                    amount: String(props.paymentInfo?.pay_amount),
                    currency: String(props.paymentInfo?.pay_currency),
                    card: {
                      name: String(form.name.value),
                      number: String(form.cardNumber.value).replace(/[^\d]/g, ""),
                      cvc: String(form.cvc.value),
                      expiryMonth: String(form.expMonth.value),
                      expiryYear: String(form.expYear.value).slice(2),
                    },
                  };
                  let reqData = {
                    ...commonData,
                    card_no: hidedCardNo(form.cardNumber.value),
                    issuer: issuer,
                    token: token,
                    fingerprint: window.black_box_tongdun,
                    forter_token_cookie: window.black_box_forter,
                    captcha: captchaToken,
                    ...taxIdData,
                  };
                  kushki?.requestToken(kushkiData, (response) => {
                    if (response.token) {
                      reqData.extra_token = response.token;
                      if (response.secureService === "KushkiOTP") {
                        window.kushkiInstance = kushki;
                        setKushkiOtp({ secureId: response.secureId, reqData });
                        setIsloading(false);
                      } else {
                        doPay(reqData);
                      }
                    } else {
                      handleError(`Kushki error: ${JSON.stringify(response)}`);
                      console.error("Error: ", response.error, "Code: ", response.code, "Message: ", response.message);
                    }
                  });
                },
                (err) => {
                  // callback for fail
                  handleError(err);
                }
              );
            })
            .catch((err) => {
              handleError(`Google captcha error: ${err}`);
            });
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else if (
        (props.method === "CreditCard" && props.payChannel === "Localpayment") ||
        (props.method === "DebitCard" && props.payChannel === "Localpayment")
      ) {
        if (props.loadedLibs["CreditCard-Localpayment"] || props.loadedLibs["DebitCard-Localpayment"]) {
          setIsloading(true);
          googleTokenize()
            .then((captchaToken) => {
              tokenize(
                props.prepayId,
                issuer,
                form,
                (token) => {
                  // callback for success
                  let reqData = {
                    ...commonData,
                    card_no: hidedCardNo(form.cardNumber.value),
                    issuer: issuer,
                    token: token,
                    fingerprint: window.black_box_tongdun,
                    forter_token_cookie: window.black_box_forter,
                    captcha: captchaToken,
                    ...taxIdData,
                  };
                  doPay(reqData);
                },
                (err) => {
                  // callback for fail
                  handleError(err);
                }
              );
            })
            .catch((err) => {
              handleError(`Google captcha error: ${err}`);
            });
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else if (props.method === "CreditCard" && props.payChannel === "Mercadopago") {
        setIsloading(true);
        googleTokenize().then((captchaToken) => {
          let reqData = {
            ...commonData,
            ...taxIdData,
            fingerprint: window.black_box_tongdun,
            forter_token_cookie: window.black_box_forter,
            captcha: captchaToken,
            installments: form.installments.stage,
          };
          let childRef = mpChildRef; // window.innerWidth <= 800 ? paypalChildRefMobile : paypalChildRef;
          childRef.current.getToken(reqData);
        });
      } else if ((props.method === "Wallet" || props.method === "ToditoCash") && props.payChannel === "Todito") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          card_no: form.todito_cardNo.value,
          security_code: form.todito_pin.value,
        };
        doPay(reqData);
      } else if (props.method === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay")) {
        let reqData = {
          ...commonData,
          address: {
            zip_code: form.postalCode.value,
            state: form.state.value,
            city: form.city.value,
            street_number: form.street.value + " " + form.no.value,
          },
        };
        doPay(reqData);
      } else if (props.method === "Crypto" && props.payChannel === "PGOne") {
        let reqData = {
          ...commonData,
          crypto_currency: props.channel?.sub_channels?.selected?.currency || props.channel?.currency,
        };
        doPay(reqData);
      } else if (props.method === "Binance" && props.payChannel === "PGOne") {
        let reqData = {
          ...commonData,
          crypto_currency: "BINANCE",
          method: "Crypto",
        };
        doPay(reqData);
      } else if (props.method === "Wallet" && props.payChannel === "Paypal") {
        const region = props?.tradeInfo?.app_info?.region;
        let reqData =
          region === "BRA"
            ? {
                ...commonData,
                ...taxIdData,
                token: data.nonce,
                device_data: data.deviceData,
              }
            : region === "MEX"
            ? { ...commonData, token: data.token }
            : {};
        doPay(reqData);
      } else if (
        props.method === "Wallet" &&
        (props.payChannel === "Daviplata" ||
          props.payChannel === "Rappipay" ||
          props.payChannel === "Dale" ||
          props.payChannel === "Movii" ||
          props.payChannel === "Nequi")
      ) {
        let reqData = {
          ...commonData,
          ...taxIdData,
          address: {
            line1: form.address.value,
          },
        };
        doPay(reqData);
      } else if (props.method === "PSE" && props.payChannel === "ACH") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.bank_id,
          address: {
            line1: form.address.value,
          },
        };
        doPay(reqData);
      } else if (props.method === "PSE" && props.payChannel === "PGOne") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.bank_id,
        };
        doPay(reqData);
      } else if (
        (props.method === "CreditCard" ||
          props.method === "RecurringCreditCard" ||
          props.method === "PlayersBankCard") &&
        props.payChannel === "Paypal"
      ) {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          googleTokenize()
            .then((captchaToken) => {
              let childRef = window.innerWidth <= 800 ? paypalChildRefMobile : paypalChildRef;
              childRef.current.getToken(
                (payload) => {
                  console.log("!!!!payload: ", payload);
                  const is3DS = (props.tradeInfo.channel_info_list || []).some((item) => {
                    return (
                      (item.method === "CreditCard" ||
                        item.method === "RecurringCreditCard" ||
                        props.method === "PlayersBankCard") &&
                      item.channel === "Paypal" &&
                      item.need_threeds
                    );
                  });
                  if (is3DS) {
                    window.braintree?.threeDSecure
                      ?.create({
                        authorization: props.paypalCreditCard.authorizationToken,
                        version: 2,
                      })
                      .then((instance) => {
                        instance
                          .verifyCard({
                            onLookupComplete: function (data, next) {
                              next();
                            },
                            amount: String(commonData.amount),
                            nonce: payload.nonce,
                            bin: payload.details.bin,
                            email: commonData.email,
                            billingAddress: {
                              givenName: payload.details?.cardholderName || "",
                              surname: payload.details?.cardholderName || "",
                              phoneNumber: commonData.phone,
                              streetAddress: form.bill_street.value,
                              extendedAddress: form.bill_no.value,
                              locality: form.bill_state.value,
                              region: form.bill_city.value,
                              postalCode: form.bill_postalCode.value,
                              countryCodeAlpha2: country2codes.find(
                                (item) => item.country_name === form.bill_country.value
                              )?.alpha2_code,
                            },
                          })
                          .then(function (payload) {
                            // if (!payload.liabilityShifted) {
                            //   handleError("PayPal 3DS fail: Liability did not shift");
                            //   return;
                            // }

                            // console.log("verification success:", payload);
                            // send nonce and verification data to your server

                            let reqData = {
                              ...commonData,
                              ...taxIdData,
                              username: payload.details.cardholderName,
                              issuer: payload.details.cardType,
                              token: payload.nonce,
                              card_no: payload.details.bin + "******" + payload.details.lastFour,
                              device_data: props.paypalCreditCard.deviceData,
                              fingerprint: window.black_box_tongdun,
                              forter_token_cookie: window.black_box_forter,
                              captcha: captchaToken,
                              threeds_authentication_id: payload.threeDSecureInfo.threeDSecureAuthenticationId,
                              card: {
                                card_no: payload.details.bin + "******" + payload.details.lastFour,
                                issuer: payload.details.cardType,
                                holder: {
                                  name: payload.details.cardholderName,
                                },
                                valid_thru_month: payload.details.expirationMonth,
                                valid_thru_year: payload.details.expirationYear,
                              },
                            };
                            if (
                              (props.method === "CreditCard" || props.method === "PlayersBankCard") &&
                              props?.tradeInfo?.app_info?.region === "BRA"
                            ) {
                              reqData.installments = form.installments.stage;
                            }
                            doPay(reqData);
                          });
                      })
                      .catch(function (err) {
                        console.log("PayPal 3DS fail", err);
                        handleError(err);
                      });
                  } else {
                    //console.log("Success", payload);
                    let reqData = {
                      ...commonData,
                      ...taxIdData,
                      username: payload.details.cardholderName,
                      issuer: payload.details.cardType,
                      token: payload.nonce,
                      card_no: payload.details.bin + "******" + payload.details.lastFour,
                      device_data: props.paypalCreditCard.deviceData,
                      fingerprint: window.black_box_tongdun,
                      forter_token_cookie: window.black_box_forter,
                      captcha: captchaToken,
                      card: {
                        card_no: payload.details.bin + "******" + payload.details.lastFour,
                        issuer: payload.details.cardType,
                        holder: {
                          name: payload.details.cardholderName,
                        },
                        valid_thru_month: payload.details.expirationMonth,
                        valid_thru_year: payload.details.expirationYear,
                      },
                    };
                    if (props.method === "CreditCard" && props?.tradeInfo?.app_info?.region === "BRA") {
                      reqData.installments = form.installments.stage;
                    }
                    doPay(reqData);
                  }
                },
                (err) => {
                  console.log("fail", err);
                  handleError(err);
                }
              );
            })
            .catch((err) => {
              handleError(`Google captcha error: ${err}`);
            });
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else if (
        ((props.method === "Boleto" || props.method === "BoletoRapido") && props.payChannel === "BS2") ||
        (props.method === "Boleto" && props.payChannel === "StarkBank") ||
        (props.method === "Boleto" && props.payChannel === "Mercadopago") ||
        (props.method === "Boleto" && props.payChannel === "CHANNEL_GROUP_ROUTER")
      ) {
        let reqData = {
          ...commonData,
          ...taxIdData,
          address: { zip_code: form.boleto_cep.value.replace(/[^\d]/g, "") },
        };
        doPay(reqData);
      } else if (props.method === "DirectDebit" && props.payChannel === "Xendit") {
        let inputPhone = form.phone.value?.replace(/[^\d+]/g, "");
        inputPhone = inputPhone?.startsWith("+63")
          ? inputPhone
          : inputPhone?.startsWith("63") && inputPhone?.length > 10
          ? "+" + inputPhone
          : "+63" + inputPhone;
        let reqData = {
          first_name: form.firstName.value.trim(),
          last_name: form.lastName.value.trim(),
          channel: props.channel?.data[0]?.bank,
          email: form.email.value?.toLowerCase(),
          phone: inputPhone,
          prev_url: document.referrer,
        };
        if (props.tradeInfo?.is_original_source_required) {
          reqData.original_source = form.source.value;
        }
        setIsloading(true);
        authorize(
          reqData,
          props.prepayId,
          (res) => {
            if (typeof res.data === "string") {
              window.location.replace(res.data);
            } else {
              handleError("Authorization Error!");
            }
          },
          (err) => {
            handleError("Authorization Error!");
          }
        );
      } else if (
        (props.method === "OTC" && props.payChannel === "Xendit") ||
        (props.method === "UPI" && props.payChannel === "Tazapay") ||
        (props.method === "Giropay" && props.payChannel === "PPRO")
      ) {
        let reqData = {
          ...commonData,
          username: form.firstName.value.trim() + " " + form.lastName.value.trim(),
        };
        doPay(reqData);
      } else if (
        (props.method === "BankTransfer" || props.method === "Cash") &&
        props.payChannel === "PGOne" &&
        region === "ECU"
      ) {
        let reqData = {
          ...commonData,
          ...taxIdData,
          username: form.firstName.value.trim() + " " + form.lastName.value.trim(),
        };
        doPay(reqData);
      } else if (props.method === "CreditCard" && props.payChannel === "Xendit") {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          googleTokenize()
            .then((captchaToken) => {
              queryKey(props.method, props.prepayId)
                .then((data) => {
                  window.Xendit.setPublishableKey(data);

                  const asYouType = new AsYouType("PH");
                  asYouType.input(form.phone.value);
                  let tokenData = {
                    amount: props.paymentInfo?.pay_amount,
                    card_number: form.cardNumber.value.replace(/[^\d]/g, ""),
                    card_exp_month: form.expMonth.value,
                    card_exp_year: form.expYear.value,
                    card_cvn: form.cvc.value,
                    is_multiple_use: false,
                    currency: "PHP",
                    should_authenticate: true,
                    billing_details: {
                      given_names: form.firstName.value.trim(), //  名
                      surname: form.lastName.value.trim(), // 姓
                      email: form.email.value.toLowerCase(),
                      mobile_number: asYouType.getNumber()?.number,
                    },
                  };
                  //console.log(tokenData);
                  window.Xendit.card.createToken(tokenData, (err, authentication) => {
                    if (err) {
                      handleError(`Xendit authentication failed! Error: ${err.message}`, undefined, err.message);
                      return;
                    } else if (authentication.status === "APPROVED" || authentication.status === "VERIFIED") {
                      let reqData = {
                        ...commonData,
                        username: form.firstName.value.trim() + " " + form.lastName.value.trim(),
                        installments: form.installments.stage,
                        token: authentication.id,
                        extra_token: authentication.authentication_id,
                        issuer: issuer,
                        fingerprint: window.black_box_tongdun,
                        forter_token_cookie: window.black_box_forter,
                        captcha: captchaToken,
                      };
                      doPay(reqData);
                    } else if (authentication.status === "IN_REVIEW" || authentication.status === "CARD_ENROLLED") {
                      setIframePopUp(authentication.payer_authentication_url);
                      setIsloading(false);
                    } else if (authentication.status === "FRAUD") {
                      handleError(`Xendit authentication failed! Error: FRAUD!`);
                    } else if (authentication.status === "FAILED") {
                      handleError(`Xendit authentication failed! Error: FAILED!`);
                    }
                  });
                })
                .catch((err) => {
                  handleError("/query-key endpoint error!");
                });
            })
            .catch((err) => {
              handleError(`Google captcha error: ${err}`);
            });
        } else {
          handleError("Xendit libs are not loaded successfully!");
        }
      } else if (props.method === "PayGo" && props.payChannel === "EDEE") {
        const asYouType = new AsYouType("KH");
        asYouType.input(form.phone.value);

        let reqData = {
          ...commonData,
          phone: asYouType.getNumber()?.number,
        };
        doPay(reqData);
      } else if (props.method === "CreditCard" && props.payChannel === "CrediMax") {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          googleTokenize()
            .then((captchaToken) => {
              let reqData = {
                ...commonData,
                username: data.name,
                card_no: data?.cardNumber?.replace(/[xX]/g, "*"),
                region: country2codes.find((item) => item.country_name === form.bill_country.value),
                token: data?.sessionId,
                fingerprint: window.black_box_tongdun,
                forter_token_cookie: window.black_box_forter,
                captcha: captchaToken,
                device_data: JSON.stringify({
                  accept_header: "application/json",
                  java_enabled: "true",
                  language: String(window.navigator.language),
                  color_depth: [1, 4, 8, 15, 16, 24, 32, 48].includes(window.screen.colorDepth)
                    ? String(window.screen.colorDepth)
                    : String(
                        [1, 4, 8, 15, 16, 24, 32, 48].reduce((result, num) => {
                          return window.screen.colorDepth - num > 0 &&
                            window.screen.colorDepth - num < window.screen.colorDepth - result
                            ? num
                            : result;
                        }, 1)
                      ),
                  screen_height: String(window.screen.height),
                  screen_width: String(window.screen.width),
                  challenge_window_size: "full_screen",
                  time_zone_offset: String(new Date().getTimezoneOffset()),
                  user_agent: window.navigator.userAgent,
                }),
              };
              doPay(reqData);
            })
            .catch((err) => {
              handleError(`Google captcha error: ${err}`);
            });
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else if (props.method === "CreditCard" && props.payChannel === "Adiq") {
        if (props.loadedLibs[props.method + "-" + props.payChannel]) {
          setIsloading(true);
          const validateChallengeCallback = (jwt, statusChallenge) => {
            //status Approved/Cancelled
            console.log("Status Challenge: " + statusChallenge);
            if (statusChallenge === "Cancelled") {
              // alert("O desafio foi cancelado pelo usuário.");
              return;
            }
            let reqData = {
              ...commonData,
              extra_token: JSON.stringify({
                code3DS: threeDs.getThreeDsCode(),
                validateToken: jwt,
              }),
            };
            doPay(reqData, { challenge: true });
          };
          const threeDs = new window.Adiq3ds("num-cc", validateChallengeCallback);
          threeDs
            .Authorization3ds()
            .then(() => {
              const code3ds = threeDs.getThreeDsCode();
              console.log("code3ds >>> :", code3ds);
              googleTokenize()
                .then((captchaToken) => {
                  tokenize(
                    props.prepayId,
                    issuer,
                    form,
                    (token) => {
                      // callback for success
                      let reqData = {
                        ...commonData,
                        card_no: hidedCardNo(form.cardNumber.value),
                        issuer: issuer,
                        token: token,
                        fingerprint: window.black_box_tongdun,
                        forter_token_cookie: window.black_box_forter,
                        captcha: captchaToken,
                        threeds_authentication_id: code3ds,
                        request_id: window.adiq_br_uuid,
                        device_data: JSON.stringify({
                          java_enabled: navigator?.javaEnabled?.() ? "Y" : "N",
                          javascript_enabled: "true",
                          language: String(window.navigator?.language || window.navigator?.userLanguage),
                          color_depth: String(window.screen?.colorDepth),
                          screen_height: String(window?.innerHeight),
                          screen_width: String(window?.innerWidth),
                          time_zone_offset: String(new Date().getTimezoneOffset()),
                          user_agent: window.navigator?.userAgent,
                        }),
                      };
                      doPay(reqData, { threeDs: threeDs });
                    },
                    (err) => {
                      // callback for fail
                      handleError(err);
                    }
                  );
                })
                .catch((err) => {
                  handleError(`Google captcha error: ${err}`);
                });
            })
            .catch((err) => {
              handleError(`Adiq Authorization3ds error: ${err}`);
            });
        } else {
          handleError(`${props.payChannel} libs are not loaded successfully :(`);
        }
      } else if (props.method === "BankTransfer" && props.payChannel === "Localpayment") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.bank_id,
          account_type: form.account_number.type,
          account_id: form.account_number.value,
        };
        doPay(reqData);
      } else if (props.method === "Cash" && props.payChannel === "Localpayment") {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.bank_id,
          bank_name: form.bank_list.value?.bank_name,
        };
        doPay(reqData);
      } else if (
        (props.method === "OpenFinance" && props.payChannel === "Mercadopago") ||
        (props.method === "OpenFinance" && props.payChannel === "Iniciador")
      ) {
        let reqData = {
          ...commonData,
          ...taxIdData,
          bank: form.bank_list.value?.bank_id,
        };
        doPay(reqData);
      } else if ((props.method === "BankTransfer" || props.method === "OTC") && props.payChannel === "HappyPay") {
        let reqData = {
          ...commonData,
          bank: form.bank_list.value?.bank_id,
        };
        doPay(reqData);
      } else if (props.method === "Wallet" && props.payChannel === "LINEPay") {
        let reqData = {
          ...commonData,
          bank: "PD-EPOINT-LINEPAY",
        };
        doPay(reqData);
      } else if (props.method === "Transfiya" && props.payChannel === "Movii") {
        let reqData = {
          ...commonData,
          phone: isProduction() ? commonData.phone : "571111100011", // test
        };
        doPay(reqData);
      } else if (props.method === "CreditCard" && (props.payChannel === "Monnet" || props.payChannel === "Payku")) {
        setIsloading(true);
        googleTokenize()
          .then((captchaToken) => {
            let reqData = {
              ...commonData,
              ...taxIdData,
              username:
                props.method === "Monnet"
                  ? form.firstName.value.trim() + " " + form.lastName.value.trim()
                  : commonData.username,
              captcha: captchaToken,
            };
            doPay(reqData);
          })
          .catch((err) => {
            handleError(`Google captcha error: ${err}`);
          });
      } else if (
        props.method === "CreditCard" &&
        (props.payChannel === "QIWI" || props.payChannel === "SHAREitPay" || props.payChannel === "Payletter")
      ) {
        setIsloading(true);
        googleTokenize()
          .then((captchaToken) => {
            let reqData = {
              ...commonData,
              captcha: captchaToken,
            };
            doPay(reqData);
          })
          .catch((err) => {
            handleError(`Google captcha error: ${err}`);
          });
      } else if (
        (props.method === "Sofort" ||
          props.method === "Neosurf" ||
          props.method === "Bancontact" ||
          props.method === "EPS" ||
          props.method === "Giropay" ||
          props.method === "iDeal" ||
          props.method === "MyBank" ||
          props.method === "P24" ||
          props.method === "Trustly") &&
        props.payChannel === "Emerchantpay"
      ) {
        let reqData = {
          ...commonData,
          username: form.firstName.value.trim() + " " + form.lastName.value.trim(),
        };
        if (props.method === "Neosurf") {
          reqData.security_code = form.voucher_number.value.replace(/[^a-zA-Z0-9]/g, "");
        }
        doPay(reqData);
      } else if (props.method === "Trustly" && props.payChannel === "PPRO") {
        let reqData = {
          ...commonData,
          username: form.firstName.value.trim() + " " + form.lastName.value.trim(),
          region: {
            alpha2_code: country2codes.find((item) => item.country_name === form.country.value)?.alpha2_code,
          },
        };
        doPay(reqData);
      } else if (
        (props.method === "Paysafecard" || props.method === "Payconiq" || props.method === "Multibanco") &&
        props.payChannel === "PPRO"
      ) {
        let reqData = {
          ...commonData,
          region: {
            alpha2_code: country2codes.find((item) => item.country_name === form.country.value)?.alpha2_code,
          },
        };
        doPay(reqData);
      } else if (
        ((props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet") ||
        ((props.method === "BankTransfer" || props.method === "Cash") &&
          (props.payChannel === "KASHIO" || (props.payChannel === "PGOne" && region === "PER"))) ||
        (props.method === "Wallet" && props.payChannel === "Tupay") ||
        (props.method === "QRCode" && props.payChannel === "PGOne" && region === "PER")
      ) {
        let reqData = {
          ...commonData,
          username: form.firstName.value.trim() + " " + form.lastName.value.trim(),
          ...taxIdData,
        };
        doPay(reqData);
      } else {
        doPay(commonData);
      }
    }
  };

  return (
    <>
      {iframePopUp && (
        <div className="iframePopUp">
          <div className="iframePopUp__window">
            <iframe src={iframePopUp} frameBorder="0" title="iframePopUp" height="100%" width="100%"></iframe>
          </div>
        </div>
      )}
      {showPolicy.visable && (
        <PrivacyPolicy
          back={closePolicy}
          region={props?.tradeInfo?.app_info?.region}
          isPrivacyPolicy={showPolicy.isPrivacyPolicy}
          provider={props.payChannel}
        />
      )}
      <div className="puff__loader" style={{ display: isLoading ? "block" : "none" }}>
        <LottiAnimation anime="loadApi" height={100} className="loader" />
      </div>

      {props.desktopView ? (
        <div className="home">
          <div className="home__container">
            <div className="home__left">
              <div
                className="home__insert"
                style={{ borderLeft: isFillingRequired || channelConfig(props.method, props.payChannel) ? "" : "none" }}
              >
                <span className="home__steps">
                  <img src={props.method ? Step1Act : Step1Pas} alt="" />
                </span>
                <p className="home__insertTitle">{t("payment_method")}</p>
                <div className="home__insertContainer" style={{ paddingTop: "20px" }}>
                  {formatMethod(props.tradeInfo?.channel_info_list)?.map((item) => {
                    return methodConfig[item?.method] !== undefined ? (
                      methodConfig[item?.method]?.notice ? (
                        <Tooltip tooltip={<p>{methodConfig[item?.method]?.notice}</p>} width={250}>
                          <PaymentMethod
                            image={methodConfig[item?.method]?.info?.image}
                            text={methodConfig[item?.method]?.info?.text}
                            type={item?.method}
                            channel={item?.channel}
                            loading={setIsloading}
                            key={item?.method}
                            handleError={handleError}
                          />
                        </Tooltip>
                      ) : (
                        <PaymentMethod
                          image={methodConfig[item?.method]?.info?.image}
                          text={methodConfig[item?.method]?.info?.text}
                          type={item?.method}
                          channel={item?.channel}
                          loading={setIsloading}
                          key={item?.method}
                          handleError={handleError}
                        />
                      )
                    ) : (
                      ""
                    );
                  })}
                </div>
              </div>

              {channelConfig(props.method, props.payChannel) && (
                <div>
                  <div
                    className="home__insert"
                    style={{ borderLeft: isFillingRequired ? "dashed 1px var(--luxtak--color)" : "none" }}
                  >
                    <span className="home__steps">
                      <img src={props.channel ? Step2Act : Step2Pas} alt="" />
                    </span>
                    <p className="home__insertTitle">
                      {props.method === "Crypto" || props.method === "Binance"
                        ? t("info_fill.crypto")
                        : t("info_fill.common")}
                    </p>
                    <div className="home__insertContainer">
                      {Array.isArray(props.channelList[props.method]) &&
                        props.channelList[props.method]?.map((item) => {
                          return (
                            <Channel data={item} key={item.key} loading={setIsloading} handleError={handleError} />
                          );
                        })}
                    </div>
                    {/* promotions and notices */}
                    {props.method === "Cash" &&
                      props.payChannel === "PayCash" &&
                      props.tradeInfo?.app_info?.region === "ECU" && (
                        <p className="home__channelPromotion__notice">
                          {t("pay_points1")}
                          <a
                            href="https://checkout.luxtak.com/public/luxtak-files/puntos-Ecuador.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("pay_points2")}
                          </a>
                          {t("pay_points3")}
                        </p>
                      )}
                  </div>
                  {props.channel.sub_channels && (
                    <div
                      className="home__insert"
                      style={{ borderLeft: isFillingRequired ? "dashed 1px var(--luxtak--color)" : "none" }}
                    >
                      <span className="home__steps">
                        <img src={props.channel.sub_channels.selected?.data?.length > 0 ? Step2Act : Step2Pas} alt="" />
                      </span>
                      <p className="home__insertTitle">
                        {t([`info_fill.${props.channel.sub_channels.type}`, "info_fill.common"])}
                      </p>
                      <div className="home__insertContainer">
                        {Array.isArray(props.channel.sub_channels.channels) &&
                          props.channel.sub_channels?.channels?.map((item) => {
                            return (
                              <Channel
                                data={item}
                                key={item.key}
                                loading={setIsloading}
                                handleError={handleError}
                                subChannel={true}
                              />
                            );
                          })}
                      </div>
                      {props.channel.sub_channels?.selected?.notice && (
                        <WarningComp>
                          <Trans
                            i18nKey={props.channel.sub_channels?.selected?.notice}
                            components={{
                              highlight: <span style={{ fontWeight: "bolder", color: "#FBA557" }} />,
                            }}
                          />
                        </WarningComp>
                      )}
                    </div>
                  )}
                </div>
              )}
              <div
                className="home__insert"
                style={{
                  borderLeft: "none",
                  paddingBottom: "100px",
                  display: isFillingRequired ? "block" : "none",
                }}
              >
                <span className="home__steps">
                  <img src={getValidityResult({ isSetForm: false }) ? Step3Act : Step3Pas} alt="" />
                </span>
                <p className="home__insertTitle">{t("filling_details")}</p>

                <div className="home--column">
                  {((props.method === "CreditCard" && props.payChannel === "CardPay") ||
                    (props.method === "CreditCard" && props.payChannel === "Movii") ||
                    (props.method === "CreditCard" && props.payChannel === "Izipay") ||
                    (props.method === "CreditCard" && props.payChannel === "IzipaySmileone") ||
                    (props.method === "CreditCard" && props.payChannel === "Multicaja") ||
                    (props.method === "CreditCard" && props.payChannel === "Adiq") ||
                    (props.method === "CreditCard" && props.payChannel === "Bamboo")) && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} />
                      </div>
                      <div className="home--row">
                        <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <CreditCardExpTime form={form} setForm={setForm} />
                      </div>

                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <Address form={form} setForm={setForm} />
                    </>
                  )}

                  {props.method === "CreditCard" && props.payChannel === "PGOne" && region === "RUS" && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} />
                      </div>
                      <div className="home--row">
                        <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <CreditCardExpTime form={form} setForm={setForm} />
                      </div>
                    </>
                  )}

                  {props.method === "CreditCard" && props.payChannel === "Kushki" && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} />
                        <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <CreditCardExpTime form={form} setForm={setForm} />
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <Address form={form} setForm={setForm} />
                      {kushkiOtp && (
                        <KushkiOtp
                          setIsLoading={setIsloading}
                          handleError={handleError}
                          data={kushkiOtp}
                          doPay={doPay}
                        />
                      )}
                    </>
                  )}

                  {((props.method === "CreditCard" && props.payChannel === "Localpayment") ||
                    (props.method === "DebitCard" && props.payChannel === "Localpayment")) && (
                    <>
                      <div style={{ marginBottom: "15px", display: "flex", alignItems: "center" }}>
                        <img src={VisaCard} alt="" />
                        <img src={MasterCard} alt="" />
                        {props.method === "CreditCard" && props.payChannel === "Localpayment" && (
                          <img src={Amex} alt="" />
                        )}
                      </div>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} />
                        <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <CreditCardExpTime form={form} setForm={setForm} />
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>

                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <Address form={form} setForm={setForm} />
                    </>
                  )}

                  {((props.method === "CreditCard" && props.payChannel === "OpenPay") ||
                    (props.method === "CreditCard" && props.payChannel === "PGOne" && region === "MEX")) && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} />
                        <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        {/* <Installment form={form} setForm={setForm} /> */}
                      </div>
                      <div className="home--row">
                        <CreditCardExpTime form={form} setForm={setForm} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <PostalCode form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>

                      <HiddenForm form={form} />
                    </>
                  )}

                  {((props.method === "CreditCard" && props.payChannel === "Mercadopago") || isMpReady) && (
                    <>
                      {window.innerWidth >= 800 && (
                        <MpCreditCard
                          form={form}
                          setForm={setForm}
                          setIsloading={setIsloading}
                          doPay={doPay}
                          handleError={handleError}
                          ref={mpChildRef}
                          mpReadyState={[isMpReady, setIsMpReady]}
                        />
                      )}
                    </>
                  )}

                  {props.method === "CreditCard" && props.payChannel === "E-Comprocessing" && (
                    <>
                      <div style={{ marginBottom: "15px" }}>
                        <img src={VisaCard} alt="" />
                        <img src={MasterCard} alt="" />
                      </div>
                      <WarningComp>
                        Please input your first and last name exactly as it appears on your credit/debit card, otherwise
                        the transaction may fail.
                      </WarningComp>
                      <div className="home--row">
                        <FirstName form={form} setForm={setForm} credircard placeholder={t("fname_ph")} />
                        <LastName form={form} setForm={setForm} credircard placeholder={t("lname_ph")} />
                        {/* <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} creditcard={true} /> */}
                        {/* <Installment form={form} setForm={setForm} /> */}
                      </div>
                      <div className="home--row">
                        <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <CreditCardExpTime form={form} setForm={setForm} />
                      </div>

                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>

                      <Address form={form} setForm={setForm} />
                    </>
                  )}

                  {(props.method === "CreditCard" ||
                    props.method === "RecurringCreditCard" ||
                    props.method === "PlayersBankCard") &&
                    props.payChannel === "Paypal" &&
                    props.paypalCreditCard.authorizationToken && (
                      <>
                        <PaypalCreditcard
                          authorizationToken={props.paypalCreditCard.authorizationToken}
                          loading={setIsloading}
                          handleError={handleError}
                          ref={paypalChildRef}
                          target="pc"
                        />
                        <div className="home--row">
                          <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        </div>
                        {props?.tradeInfo?.app_info?.region === "BRA" && (
                          <div className="home--row">
                            {(props.method === "CreditCard" || props.method === "PlayersBankCard") && (
                              <Installment form={form} setForm={setForm} />
                            )}
                            <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          </div>
                        )}

                        <Address form={form} setForm={setForm} />
                      </>
                    )}
                  {props.method === "CreditCard" && props?.tradeInfo?.app_info?.region === "PHL" && (
                    <>
                      <div className="home--row">
                        <CreditCardNumber form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <CreditCardExpTime form={form} setForm={setForm} />
                      </div>
                      <div className="home--row">
                        <Installment form={form} setForm={setForm} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <FirstName form={form} setForm={setForm} />
                        <LastName form={form} setForm={setForm} />
                      </div>
                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <Address form={form} setForm={setForm} />
                    </>
                  )}

                  {props.loadedLibs["CreditCard-CrediMax"] && window.innerWidth >= 800 && (
                    <CrediMax
                      style={{
                        display: props.method === "CreditCard" && props.payChannel === "CrediMax" ? "block" : "none",
                      }}
                      ref={crediMaxChildRef}
                      payRequest={payRequest}
                      setIsLoading={setIsloading}
                      form={form}
                      setForm={setForm}
                      isReadyToPay={isReadyToPay}
                      handleError={handleError}
                    />
                  )}
                  {props.method === "CreditCard" &&
                    props.payChannel === "CrediMax" &&
                    !props.loadedLibs["CreditCard-CrediMax"] && (
                      <div style={{ textAlign: "center", paddingBottom: "15px" }}>
                        <CircularProgress size={24} style={{ color: "var(--luxtak--color)" }} />
                      </div>
                    )}

                  {props.method === "CreditCard" && props.payChannel === "CrediMax" && window.innerWidth >= 800 && (
                    <>
                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <Address form={form} setForm={setForm} />
                    </>
                  )}

                  {((props.method === "Wallet" && props.payChannel === "QIWI") ||
                    (props.method === "SBP" && props.payChannel === "PGOne")) && (
                    <>
                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}

                  {(props.method === "Wallet" || props.method === "ToditoCash") && props.payChannel === "Todito" && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <p className="home--fillTitle">{t("todito_notice")}</p>
                      <div className="home--row">
                        <CardNo form={form} setForm={setForm} />
                        <PinNo form={form} setForm={setForm} />
                      </div>
                    </>
                  )}

                  {props.method === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay") && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <State form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <City form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <PostalCode form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <StreetAndNumber form={form} setForm={setForm} />
                      </div>
                    </>
                  )}
                  {((props.method === "BankTransfer" && props.payChannel === "SafetyPay") ||
                    (props.method === "Khipu" && props.payChannel === "Khipu") ||
                    (props.method === "Efecty" && props.payChannel === "Payty") ||
                    (props.method === "Efecty" && props.payChannel === "PaytyV2") ||
                    (props.method === "Wallet" &&
                      (props.payChannel === "PicPay" ||
                        (props.payChannel === "Paypal" && props.tradeInfo?.app_info?.region === "BRA") ||
                        props.payChannel === "AME" ||
                        props.payChannel === "TPaga" ||
                        props.payChannel === "MOL" ||
                        props.payChannel === "Chek" ||
                        props.payChannel === "ClaroPay" ||
                        props.payChannel === "Mercadopago" ||
                        props.payChannel === "Vita")) ||
                    (props.method === "DepositExpress" && props.payChannel === "Levpay") ||
                    ((props.method === "PagoEfectivo" || props.method === "BankTransfer" || props.method === "Cash") &&
                      props.payChannel === "PagoEfectivo") ||
                    (props.method === "KLAP" && props.payChannel === "Multicaja") ||
                    (props.method === "Cash" && props.payChannel === "Multicaja") ||
                    (props.method === "BankTransfer" && props.payChannel === "Multicaja") ||
                    (props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
                    (props.method === "CreditCard" && props.payChannel === "Payku") ||
                    ((props.method === "Pago46" || props.method === "WebPay") && props.payChannel === "Payku") ||
                    (props.method === "BankTransfer" && props.payChannel === "ETpay") ||
                    (props.method === "SuRed" && props.payChannel === "PayValida") ||
                    (props.method === "GANA" && props.payChannel === "PayValida") ||
                    (props.method === "Bancolombia" && props.payChannel === "PayValida") ||
                    (props.method === "PlayersBankPIX" && props.payChannel === "BS2") ||
                    ((props.method === "PagoFacil" || props.method === "Servipag" || props.method === "Rapipago") &&
                      props.payChannel === "Localpayment") ||
                    (props.method === "PagoFacil" && props.payChannel === "PagoFacil") ||
                    (props.method === "SPEI" &&
                      (props.payChannel === "OpenPay" ||
                        props.payChannel === "STP" ||
                        props.payChannel === "Banwire")) ||
                    (props.method === "Lottery" &&
                      (props.payChannel === "Caixa" || props.payChannel === "Mercadopago")) ||
                    (props.method === "Rapipago" && props.payChannel === "Rapipago")) && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}

                  {props.method === "PSE" && (props.payChannel === "Payty" || props.payChannel === "PaytyV2") && (
                    <div className="home--row">
                      <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                    </div>
                  )}

                  {props.method === "Wallet" &&
                    (props.payChannel === "Daviplata" ||
                      props.payChannel === "Rappipay" ||
                      props.payChannel === "Dale" ||
                      props.payChannel === "Movii" ||
                      props.payChannel === "Nequi") && (
                      <>
                        <div className="home--row">
                          <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        </div>
                        <div className="home--row">
                          <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        </div>
                        <div className="home--row">
                          <AddressBox form={form} setForm={setForm} />
                        </div>
                      </>
                    )}

                  {props.method === "PSE" && props.payChannel === "ACH" && (
                    <>
                      <div className="home--row">
                        <BankList form={form} setForm={setForm} />
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <AddressBox form={form} setForm={setForm} />
                      </div>
                    </>
                  )}

                  {props.method === "PSE" && props.payChannel === "PGOne" && (
                    <>
                      <div className="home--row">
                        <BankList form={form} setForm={setForm} />
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}

                  {props.method === "PIX" &&
                    (props.payChannel === "BS2" ||
                      props.payChannel === "Bradesco" ||
                      props.payChannel === "Rendimento" ||
                      props.payChannel === "Nupay" ||
                      props.payChannel === "Genial" ||
                      props.payChannel === "StarkBank" ||
                      props.payChannel === "CHANNEL_GROUP_ROUTER" ||
                      props.payChannel === "Mercadopago" ||
                      props.payChannel === "StarkBankTransferSmile" ||
                      props.payChannel === "BTG" ||
                      props.payChannel === "Fastcash" ||
                      props.payChannel === "Pagsmile" ||
                      props.payChannel === "Delbank" ||
                      props.payChannel === "Starpago") && (
                      <>
                        <div className="home--row">
                          <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          {form.name.show && <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />}
                        </div>
                        <div className="home--row">
                          {form.email.show && <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />}
                        </div>
                      </>
                    )}

                  {((props.method === "Wallet" &&
                    (props.payChannel === "Paysafecard" ||
                      props.payChannel === "Skrill" ||
                      props.payChannel === "Eprepag" ||
                      props.payChannel === "PaypalXCG" ||
                      props.payChannel === "Papara")) ||
                    (props.method === "Crypto" && props.payChannel === "PGOne") ||
                    (props.method === "Binance" && props.payChannel === "PGOne") ||
                    (props.method === "PSE" && props.payChannel === "PayValida") ||
                    (props.method === "BankTransfer" && props.payChannel === "PGOne" && region === "CHL")) && (
                    <>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}
                  {(((props.method === "Boleto" || props.method === "BoletoRapido") && props.payChannel === "BS2") ||
                    (props.method === "Boleto" && props.payChannel === "StarkBank") ||
                    (props.method === "Boleto" && props.payChannel === "Mercadopago") ||
                    (props.method === "Boleto" && props.payChannel === "CHANNEL_GROUP_ROUTER")) && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Cep form={form} setForm={setForm} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}
                  {((props.method === "Wallet" &&
                    (props.payChannel === "AlipayHK" ||
                      props.payChannel === "GCash" ||
                      props.payChannel === "KakaoPayAlipay" ||
                      props.payChannel === "KakaoPay" ||
                      props.payChannel === "TOSS" ||
                      props.payChannel === "PAYCO" ||
                      props.payChannel === "SamsungPay" ||
                      props.payChannel === "DANA" ||
                      props.payChannel === "TNG" ||
                      props.payChannel === "BOOST" ||
                      props.payChannel === "RabbitLinePay" ||
                      props.payChannel === "BPI" ||
                      props.payChannel === "TrueMoney" ||
                      props.payChannel === "GcashXendit" ||
                      props.payChannel === "Paymaya" ||
                      props.payChannel === "PayNow" ||
                      props.payChannel === "GrabPay" ||
                      props.payChannel === "OVO" ||
                      props.payChannel === "MADA" ||
                      props.payChannel === "KNet" ||
                      props.payChannel === "YooMoney" ||
                      props.payChannel === "Fawry" ||
                      props.payChannel === "AstroPay" ||
                      props.payChannel === "Fpay" ||
                      props.payChannel === "NaverPay" ||
                      props.payChannel === "FawryPay")) ||
                    ((props.method === "GCash" ||
                      props.method === "CreditCard" ||
                      props.method === "NetBanking" ||
                      props.method === "UPI") &&
                      props.payChannel === "SHAREitPay") ||
                    ((props.method === "AlfaClick" ||
                      props.method === "Qiwi" ||
                      props.method === "WebMoney" ||
                      props.method === "YandexMoney" ||
                      props.method === "UnionPay") &&
                      props.payChannel === "Payssion") ||
                    (props.method === "PiPay" && props.payChannel === "PiPay") ||
                    (props.method === "AlipayPlus" && props.payChannel === "AlipayPlus") ||
                    ((props.method === "CreditCard" ||
                      props.method === "BankTransfer" ||
                      props.method === "VirtualAccount") &&
                      props.payChannel === "Payletter") ||
                    (props.method === "OXXO" &&
                      (props.payChannel === "Banwire" ||
                        props.payChannel === "CardPay" ||
                        props.payChannel === "Bamboo" ||
                        props.payChannel === "Localpayment")) ||
                    (props.method === "OXXOPay" && (props.payChannel === "Conekta" || props.payChannel === "PGOne")) ||
                    (props.method === "CoDi" && props.payChannel === "STP") ||
                    (props.method === "Cash" && props.payChannel === "PayCash") ||
                    (props.method === "Cash" && props.payChannel === "SafetyPay") ||
                    (props.method === "SberBank" && props.payChannel === "YooMoney") ||
                    (props.method === "Transfiya" && props.payChannel === "Movii") ||
                    (props.method === "BNCR" && props.payChannel === "PayCash") ||
                    (props.method === "Cash" && props.payChannel === "FawryPay") ||
                    ((props.method === "BCR" || props.method === "BNCR") && props.payChannel === "PayValida")) && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      {props.payChannel === "Fpay" && <Address form={form} setForm={setForm} />}
                    </>
                  )}

                  {((props.method === "CreditCard" && props.payChannel === "QIWI") ||
                    (props.method === "Khipu" && props.payChannel === "PGOne")) && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}

                  {props.method === "PayGo" && props.payChannel === "EDEE" && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      {paygoOtp && (
                        <PaygoOtp phone={form.phone.value} setIsLoading={setIsloading} handleError={handleError} />
                      )}
                    </>
                  )}
                  {props.method === "BankTransfer" && props.payChannel === "Localpayment" && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <BankList
                          form={form}
                          setForm={setForm}
                          tradeInfo={props.tradeInfo}
                          region={props.tradeInfo?.app_info?.region}
                        />
                      </div>
                      <div className="home--row">
                        <AccountNumber form={form} setForm={setForm} region={props.tradeInfo?.app_info?.region} />
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}

                  {(props.method === "BankTransfer" || props.method === "OTC") && props.payChannel === "HappyPay" && (
                    <BankList
                      form={form}
                      setForm={setForm}
                      tradeInfo={props.tradeInfo}
                      region={props.tradeInfo?.app_info?.region}
                      type={props.method === "OTC" ? "store" : "bank"}
                    />
                  )}

                  {props.method === "Cash" && props.payChannel === "Localpayment" && (
                    <>
                      <div className="home--row">
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <BankList form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                      </div>
                    </>
                  )}

                  {((props.method === "OpenFinance" && props.payChannel === "Mercadopago") ||
                    (props.method === "OpenFinance" && props.payChannel === "Iniciador")) && (
                    <>
                      <div className="home--row">
                        {/* <BankList type="popup" form={form} setForm={setForm} tradeInfo={props.tradeInfo} /> */}
                        <BankListPopUp form={form} setForm={setForm} />
                        <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} size="medium" />
                      </div>
                      <div className="home--row">
                        <TaxId form={form} setForm={setForm} tradeInfo={props.tradeInfo} size="medium" />
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} size="medium" />
                      </div>
                    </>
                  )}
                  {((props.method === "DirectDebit" && props.payChannel === "Xendit") ||
                    (props.method === "OTC" && props.payChannel === "Xendit") ||
                    (props.method === "Giropay" && props.payChannel === "PPRO")) && (
                    <>
                      <div className="home--row">
                        <FirstName form={form} setForm={setForm} />
                        <LastName form={form} setForm={setForm} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} />
                        <MobilePhone form={form} setForm={setForm} />
                      </div>
                    </>
                  )}

                  {(((props.method === "BankTransfer" || props.method === "Cash" || props.method === "CreditCard") &&
                    props.payChannel === "Monnet") ||
                    ((props.method === "BankTransfer" || props.method === "Cash") &&
                      (props.payChannel === "KASHIO" || (props.payChannel === "PGOne" && region === "PER"))) ||
                    (props.method === "Wallet" && props.payChannel === "Tupay") ||
                    (props.method === "QRCode" && props.payChannel === "PGOne" && region === "PER")) && (
                    <>
                      <div className="home--row">
                        <FirstName form={form} setForm={setForm} />
                        <LastName form={form} setForm={setForm} />
                      </div>
                      <div className="home--row">
                        <TaxId form={form} setForm={setForm} />
                        <Email form={form} setForm={setForm} />
                      </div>
                      <div className="home--row">
                        <MobilePhone form={form} setForm={setForm} />
                      </div>
                    </>
                  )}
                  {props.method === "UPI" && props.payChannel === "Tazapay" && (
                    <>
                      <div className="home--row">
                        <FirstName form={form} setForm={setForm} />
                        <LastName form={form} setForm={setForm} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} />
                      </div>
                    </>
                  )}

                  {(props.method === "BankTransfer" || props.method === "Cash") &&
                    props.payChannel === "PGOne" &&
                    region === "ECU" && (
                      <>
                        <div className="home--row">
                          <FirstName form={form} setForm={setForm} />
                          <LastName form={form} setForm={setForm} />
                        </div>
                        <div className="home--row">
                          <TaxId form={form} setForm={setForm} />
                          <Email form={form} setForm={setForm} />
                        </div>
                      </>
                    )}

                  {(props.method === "Sofort" ||
                    props.method === "Neosurf" ||
                    props.method === "Bancontact" ||
                    props.method === "EPS" ||
                    props.method === "Giropay" ||
                    props.method === "iDeal" ||
                    props.method === "MyBank" ||
                    props.method === "P24" ||
                    props.method === "Trustly") &&
                    props.payChannel === "Emerchantpay" && (
                      <>
                        {props.method === "Neosurf" && (
                          <div className="home--row">
                            <VoucherNumber form={form} setForm={setForm} />
                          </div>
                        )}
                        <WarningComp>Please type in your real name, otherwise the transaction will fail.</WarningComp>
                        <div className="home--row">
                          <FirstName form={form} setForm={setForm} />
                          <LastName form={form} setForm={setForm} />
                        </div>
                        <div className="home--row">
                          <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <MobilePhone form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        </div>
                        <Address form={form} setForm={setForm} />
                      </>
                    )}

                  {props.method === "Trustly" && props.payChannel === "PPRO" && (
                    <>
                      <div className="home--row">
                        <FirstName form={form} setForm={setForm} />
                        <LastName form={form} setForm={setForm} />
                      </div>
                      <div className="home--row">
                        <Email form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                        <Country form={form} setForm={setForm} />
                      </div>
                    </>
                  )}

                  {(props.method === "Paysafecard" || props.method === "Payconiq" || props.method === "Multibanco") &&
                    props.payChannel === "PPRO" && (
                      <>
                        <div className="home--row">
                          <Name form={form} setForm={setForm} tradeInfo={props.tradeInfo} />
                          <Country form={form} setForm={setForm} />
                        </div>
                      </>
                    )}

                  {props.tradeInfo?.is_original_source_required && props.method && props.payChannel && (
                    <Source form={form} setForm={setForm} />
                  )}
                  {submitData && <SubmitForm data={submitData} />}
                </div>
              </div>
            </div>
            <div className="home__right">
              <div className="home__time">
                <TimeRemaining
                  image={TimeIcon}
                  time={props?.tradeInfo?.create_time}
                  countdown={props?.tradeInfo?.timeout_time}
                  disable={disablePay}
                />
              </div>
              <div className="home__payDetail">
                <TitleWithDetails show={true} />
                <hr />
                <div className="home__payDetail--container">
                  <p className="home__payDetail--text">{t("order_value")}</p>
                  <p className="home__payDetail--value">
                    <FormatMoney type="order" />
                  </p>
                </div>
                <Fees
                  fees={props?.paymentInfo?.fees?.additional_detail}
                  styles={{
                    container: "home__payDetail--container",
                    content: { text: "home__payDetail--text", value: "home__payDetail--value" },
                  }}
                  region={props.tradeInfo?.app_info?.region}
                />
                {/* {props?.paymentInfo?.fees?.additional_detail?.map?.((item) => (
                <div className="home__payDetail--container">
                  <p className="home__payDetail--text">
                    {item.fee_type === 100
                      ? item.fee_name?.lastIndexOf?.("-") !== -1
                        ? item.fee_name?.slice?.(0, item.fee_name.lastIndexOf("-"))
                        : item.fee_name
                      : t(`fee_${item.fee_type}`)}
                  </p>
                  <p className="home__payDetail--value">
                    <FormatMoney type="fee" data={item.fee_amount} />
                  </p>
                </div>
              ))} */}
                {!!props?.paymentInfo?.fees?.discountable_sum && (
                  <div className="home__payDetail--container">
                    <p className="home__payDetail--text">{t("discount")}</p>
                    <p className="home__payDetail--value">
                      -
                      <FormatMoney type="discount" />
                    </p>
                  </div>
                )}
                <hr />
                {((props.method === "CreditCard" && props.payChannel === "E-Comprocessing") ||
                  (props.method === "CreditCard" &&
                    props.payChannel === "Paypal" &&
                    props.tradeInfo?.app_info?.region === "GLB")) && (
                  <div
                    style={{
                      fontFamily: "Montserrat-Regular",
                      fontSize: "11px",
                      textAlign: "center",
                      border: "1px dashed #878787",
                      padding: "3px",
                    }}
                  >
                    {props.method === "CreditCard" && props.payChannel === "E-Comprocessing" && (
                      <>
                        <p>{t("ecp_notice1")}</p>
                        <p>{t("ecp_notice2")}</p>
                      </>
                    )}
                    {props.method === "CreditCard" &&
                      props.payChannel === "Paypal" &&
                      props.tradeInfo?.app_info?.region === "GLB" && (
                        <p>
                          *Depending on your issuing bank, there may be foreign transaction fees charged on this
                          transaction
                        </p>
                      )}
                  </div>
                )}
                <div className="home__payDetail--container" style={{ marginTop: "50px" }}>
                  <p className="home__payDetail__totalText">{t("total")}</p>
                  <p className="home__payDetail__totalValue">
                    <FormatMoney type="total" />
                  </p>
                </div>
                {props.method === "RecurringCreditCard" && <Subscription />}
                {!(props.method === "Wallet" && props.payChannel === "Paypal" && props.paypalWallet.isReady) && (
                  <button
                    className="home__payDetail__button"
                    onClick={() => {
                      if (props.method === "CreditCard" && props.payChannel === "CrediMax") {
                        crediMaxChildRef.current.pay();
                      } else {
                        checkIsAllSelected() && payRequest();
                      }
                    }}
                    disabled={isDisabled}
                    style={{ backgroundColor: isDisabled ? "#fddab9" : "var(--luxtak--color)" }}
                  >
                    {t("confirm")}
                  </button>
                )}
                {((props.method === "Wallet" &&
                  props.payChannel === "Paypal" &&
                  props.paypalWallet.authorizationToken) ||
                  props.paypalWallet.isReady) &&
                  props.tradeInfo?.app_info?.region === "BRA" && (
                    <PaypalButton
                      setIsloading={setIsloading}
                      handleError={handleError}
                      setForm={setForm}
                      containerId="paypal-button-container-pc"
                      payRequest={payRequest}
                    />
                  )}

                {((props.method === "Wallet" && props.payChannel === "Paypal") || props.paypalWallet.isReady) &&
                  props.tradeInfo?.app_info?.region === "MEX" && (
                    <PaypalButtonNative
                      setIsloading={setIsloading}
                      handleError={handleError}
                      setForm={setForm}
                      containerId="paypal-button-container-pc"
                      payRequest={payRequest}
                    />
                  )}

                <div className="home__payDetail__checkboxContainer">
                  <Checkbox
                    size="small"
                    checked={form.isPolicyAccepted}
                    id="policy-checkbox"
                    onChange={(event) => {
                      setForm((prev) => ({ ...prev, isPolicyAccepted: event.target.checked }));
                    }}
                  />
                  <span className="home__payDetail__privacyText1">
                    {t("privacy_policy1")}
                    <span
                      className="home__payDetail__privacyText2"
                      onClick={() => {
                        if (props.method === "CreditCard" && props.payChannel === "E-Comprocessing") {
                          window.open("https://www.smile.one/eu/message/about/reula");
                        } else {
                          setShowPolicy({ visable: true, isPrivacyPolicy: false });
                        }
                      }}
                    >
                      {t("privacy_policy2")}
                    </span>
                    {t("privacy_policy3")}
                    <span
                      className="home__payDetail__privacyText2"
                      onClick={() => {
                        if (props.method === "CreditCard" && props.payChannel === "E-Comprocessing") {
                          window.open("https://www.smile.one/eu/message/about/rprivacy");
                        } else {
                          setShowPolicy({ visable: true, isPrivacyPolicy: true });
                        }
                      }}
                    >
                      {t("privacy_policy4")}
                    </span>
                    {t("privacy_policy5")}
                  </span>
                </div>
                <p className="home--error" style={{ display: form.isPolicyAccepted ? "none" : "block" }}>
                  {t("privacy_policy_error")}
                </p>
                {props.method === "CreditCard" && props.payChannel === "E-Comprocessing" && (
                  <div className="home__payDetail__support">
                    <div className="home__payDetail__support__row">
                      <img src={MailImg} alt="" />
                      <p>{t("help_contact")} supporteu@smile.one </p>
                    </div>
                  </div>
                )}
                {props.tradeInfo?.app_info?.region === "PER" && props.payChannel === "OpenPay" && (
                  <div className="home__payDetail__support">
                    <div className="home__payDetail__support__row">
                      <img src={MailImg} alt="" />
                      <p>support.latam@luxtak.com</p>
                    </div>
                    {/* <div className="home__payDetail__support__row">
                      <img src={PhoneImg} alt="" />
                      <p>981197235</p>
                    </div> */}
                    <div className="home__payDetail__support__row">
                      <img src={LocationImg} alt="" />
                      <p>Av. República de Panamá Of 403, Distrito San Isidro, Lima</p>
                    </div>
                  </div>
                )}

                <div className="home__payDetail__support">
                  <div className="home__payDetail__support__row" style={{ flexDirection: "column" }}>
                    <p style={{ textAlign: "center", padding: "0 10px" }}>{t("pci_notice")}</p>
                    <img src={PCI} alt="" />
                  </div>
                </div>

                {props.returnUrl && (
                  <div className="home__payDetail__return">
                    <span
                      onClick={() => {
                        window.location.replace(props.returnUrl);
                      }}
                    >
                      {t("back_shop")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="homeMobile"
          style={focusOnInput ? { paddingBottom: "0px" } : {}}
          onClick={() => {
            setFocusOnInput(false);
          }}
        >
          <div className="homeMobile__channel">
            <div
              className="homeMobile__channel__top"
              onClick={() => {
                setDropDownState((prev) => !prev);
              }}
            >
              <p className="homeMobile__channelName">{props?.tradeInfo?.app_info?.app_name}</p>
              <div className="homeMobile__channelRight">
                {props?.tradeInfo?.app_info?.icon_url && (
                  <img
                    className="homeMobile__channelRight__icon"
                    src={props?.tradeInfo?.app_info?.icon_url ? props?.tradeInfo?.app_info?.icon_url : null}
                    alt=""
                  />
                )}
                <img
                  className={classNames("homeMobile__channelRight__arrow", {
                    arrow__0: !dropDownState,
                    arrow__90: dropDownState,
                  })}
                  src={RightArrowBlack}
                  alt=""
                />
              </div>
            </div>
            <div className="homeMobile__channel__bottom" style={{ display: dropDownState ? "block" : "none" }}>
              <div className="home__payDetail--container">
                <p className="home__payDetail--text homeMobile__channel__bottom__trxIdText">{t("trx_id")}</p>
                <EllipsisScroll
                  className={"home__payDetail--value homeMobile__channel__bottom__trxIdValue"}
                  text={props?.tradeInfo?.out_trade_no}
                />
              </div>
              <div className="home__payDetail--container">
                <p className="home__payDetail--text">{t("product_description")}</p>
                <EllipsisScroll className={"home__payDetail--value"} text={props?.tradeInfo?.subject} />
              </div>
            </div>
          </div>
          <div className="homeMobile__slection">
            <p className="homeMobile__paymentMethod">{t("payment_method")}</p>

            {formatMethod(props.tradeInfo?.channel_info_list)?.map((item) => {
              return methodConfig[item?.method] !== undefined ? (
                <PaymentMethodMobile
                  image={methodConfig[item?.method]?.info?.image}
                  imageMobile={methodConfig[item?.method]?.info?.imageMobile}
                  text={methodConfig[item?.method]?.info?.text}
                  channelNotice={methodConfig[item?.method]?.notice}
                  type={item?.method}
                  channel={item?.channel}
                  loading={setIsloading}
                  form={form}
                  setForm={setForm}
                  setFocusOnInput={setFocusOnInput}
                  paypalChildRefMobile={paypalChildRefMobile}
                  submitData={submitData}
                  paygoOtp={paygoOtp}
                  kushkiOtp={kushkiOtp}
                  setShowPolicy={setShowPolicy}
                  key={item?.method}
                  crediMaxChildRef={crediMaxChildRef}
                  payRequest={payRequest}
                  isReadyToPay={isReadyToPay}
                  handleError={handleError}
                  doPay={doPay}
                  mpChildRef={mpChildRef}
                  mpReadyState={[isMpReady, setIsMpReady]}
                  isFillingRequired={isFillingRequired}
                  providerId={providerId}
                />
              ) : (
                ""
              );
            })}
          </div>

          <div
            className="homeMobile__footer"
            style={
              focusOnInput
                ? {
                    position: "static",
                    marginTop: "10px",
                  }
                : {}
            }
          >
            <div
              className="homeMobile__footer__sort"
              onClick={() => {
                setFooterDropDownState((prev) => !prev);
              }}
            >
              <img
                className={classNames({
                  arrow__180: !footerDropDownState,
                  arrow__0: footerDropDownState,
                })}
                src={SortDown}
                alt=""
              />
            </div>

            <div style={{ display: footerDropDownState ? "block" : "none" }}>
              {props.method === "RecurringCreditCard" && <Subscription />}
              <div className="homeMobile__footer__time">
                <TimeRemaining
                  image={TimeIcon}
                  time={props?.tradeInfo?.create_time}
                  countdown={props?.tradeInfo?.timeout_time}
                  disable={disablePay}
                />
              </div>
              {((props.method === "CreditCard" && props.payChannel === "E-Comprocessing") ||
                (props.method === "CreditCard" &&
                  props.payChannel === "Paypal" &&
                  props.tradeInfo?.app_info?.region === "GLB")) && (
                <div
                  style={{
                    fontFamily: "Montserrat-Regular",
                    fontSize: "11px",
                    textAlign: "center",
                    border: "1px dashed #878787",
                    padding: "3px",
                  }}
                >
                  {props.method === "CreditCard" && props.payChannel === "E-Comprocessing" && (
                    <>
                      <p>{t("ecp_notice1")}</p>
                      <p>{t("ecp_notice2")}</p>
                    </>
                  )}
                  {props.method === "CreditCard" &&
                    props.payChannel === "Paypal" &&
                    props.tradeInfo?.app_info?.region === "GLB" && (
                      <p>
                        *Depending on your issuing bank, there may be foreign transaction fees charged on this
                        transaction
                      </p>
                    )}
                </div>
              )}
              <div className="homeMobile__footer__order">
                <p> {t("order_value")}</p>
                <p>
                  <FormatMoney type="order" />
                </p>
              </div>
              <Fees
                fees={props?.paymentInfo?.fees?.additional_detail}
                styles={{
                  container: "homeMobile__footer__order",
                }}
                region={props.tradeInfo?.app_info?.region}
              />
              {/* {props?.paymentInfo?.fees?.additional_detail?.map?.((item) => (
              <div className="homeMobile__footer__order">
                <p>
                  {item.fee_type === 100
                    ? item.fee_name?.lastIndexOf?.("-") !== -1
                      ? item.fee_name?.slice?.(0, item.fee_name.lastIndexOf("-"))
                      : item.fee_name
                    : t(`fee_${item.fee_type}`)}
                </p>
                <p>
                  <FormatMoney type="fee" data={item.fee_amount} />
                </p>
              </div>
            ))} */}
              {!!props?.paymentInfo?.fees?.discountable_sum && (
                <div className="homeMobile__footer__order">
                  <p> {t("discount")}</p>
                  <p>
                    -
                    <FormatMoney type="discount" />
                  </p>
                </div>
              )}
            </div>

            <div className="homeMobile__footer__order">
              <p className="homeMobile__footer__totalText"> {t("total")}</p>
              <p className="homeMobile__footer__totalValue">
                <FormatMoney type="total" />
              </p>
            </div>

            {!(props.method === "Wallet" && props.payChannel === "Paypal" && props.paypalWallet.isReady) && (
              <button
                className="homeMobile__footer__payButton"
                onClick={() => {
                  if (props.method === "CreditCard" && props.payChannel === "CrediMax") {
                    crediMaxChildRef.current.pay();
                  } else {
                    checkIsAllSelected() && payRequest();
                  }
                }}
                disabled={isDisabled}
                style={{ backgroundColor: isDisabled ? "#fdcea2" : "var(--luxtak--color)" }}
              >
                {t("confirm")}
              </button>
            )}
            {((props.method === "Wallet" && props.payChannel === "Paypal" && props.paypalWallet.authorizationToken) ||
              props.paypalWallet.isReady) &&
              props.tradeInfo?.app_info?.region === "BRA" && (
                <PaypalButton
                  setIsloading={setIsloading}
                  handleError={handleError}
                  setForm={setForm}
                  containerId="paypal-button-container-mobile"
                  payRequest={payRequest}
                  mobile
                />
              )}
            {((props.method === "Wallet" && props.payChannel === "Paypal") || props.paypalWallet.isReady) &&
              props.tradeInfo?.app_info?.region === "MEX" && (
                <PaypalButtonNative
                  setIsloading={setIsloading}
                  handleError={handleError}
                  setForm={setForm}
                  containerId="paypal-button-container-mobile"
                  payRequest={payRequest}
                />
              )}
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    desktopView: state.desktopView,
    method: state.method,
    payChannel: state.payChannel,
    channel: state.channel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    channelList: state.channelList,
    prepayId: state.prepayId,
    returnUrl: state.returnUrl,
    loadedLibs: state.loadedLibs,
    paypalCreditCard: state.paypalCreditCard,
    paypalWallet: state.paypalWallet,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMethod: (item) => dispatch(setMethod(item)),
    setChannel: (item) => dispatch(setChannel(item)),
    setPayData: (item) => dispatch(setPayData(item)),
    setTradeInfo: (item) => dispatch(setTradeInfo(item)),
    setTradeStatus: (item) => dispatch(setTradeStatus(item)),
    changeMethod: (method, channel, setIsLoading, handleError) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError)),
    setPaypalCreditCard: (item) => dispatch(setPaypalCreditCard(item)),
    getInfoById: (setForm, idObj) => dispatch(getInfoById(setForm, idObj)),
    setAlert: (item) => dispatch(setAlert(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
