import React, { useState, useEffect } from "react";
import "./Checkout.scss";
import { connect } from "react-redux";
import { setChannel } from "../../../redux/actions";
import req from "../../../utils/axios";
import TitleWithDetails from "../../comp/TitleWithDetails";
import { useTranslation } from "react-i18next";
import CheckoutSafetyPay from "./CheckoutSafetyPay";
import OrderDetails from "../../comp/OrderDetails";
import CheckoutSpei from "./CheckoutSpei";
import CheckoutPaynet from "./CheckoutPaynet";
import CheckoutOxxo from "./CheckoutOxxo";
import CheckoutWallet from "./CheckoutWallet";
import CheckoutBoleto from "./CheckoutBoleto";
import CheckoutPix from "./CheckoutPix";
import CheckoutLottery from "./CheckoutLottery";
import CheckoutDepositExpress from "./CheckoutDepositExpress";
import LottiAnimation from "../../comp/LottiAnimation";
import { toast } from "react-toastify";
import { FormatMoney } from "../../../utils/formats";
import { loadAddScript } from "../../../utils/advert.js";
import CheckoutSeven11 from "./CheckoutSeven11";
import { useHistory } from "react-router-dom";
import CheckoutKlapCash from "./CheckoutKlapCash";
import CheckoutPayCash from "./CheckoutPayCash";
import CheckoutPayvalida from "./CheckoutPayvalida";
import { returnUrlAsParam } from "../../../utils/queryUrl";
import CheckoutCodi from "./CheckoutCodi";
import CheckoutBarcode from "./CheckoutBarcode";
import CheckoutBankTransferLocalpayment from "./CheckoutBankTransferLocalpayment";
import CheckoutTransfiya from "./CheckoutTransfiya";
import CheckoutBankCashCommon from "./CheckoutBankCashCommon";
import CheckoutCrypto from "./CheckoutCrypto";
import AutoQuery from "../../comp/AutoQuery";
import CheckoutKashIo from "./CheckoutKashIo";
import CheckoutQr from "./CheckoutQr";
import CheckoutMultibanco from "./CheckoutMultibanco";
import CheckoutFawryCash from "./CheckoutFawryCash";

function Checkout(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const [image, setImage] = useState("");
  const [pdf, setPdf] = useState("");
  const [otcData, setOtcData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const region = props.tradeInfo?.app_info?.region;
  useEffect(() => {
    loadAddScript(props.tradeInfo?.app_info?.region);
    if (
      (props.method === "OXXO" && props.payChannel === "CardPay") ||
      (props.method === "OXXO" && props.payChannel === "Banwire") ||
      (props.method === "Cash" && props.payChannel === "OpenPay") ||
      (props.method === "SPEI" && props.payChannel === "OpenPay") ||
      (props.method === "Boleto" &&
        (props.payChannel === "BS2" ||
          props.payChannel === "StarkBank" ||
          props.payChannel === "Mercadopago" ||
          props.payChannel === "CHANNEL_GROUP_ROUTER")) ||
      (props.method === "OXXOPay" && (props.payChannel === "Conekta" || props.payChannel === "PGOne")) ||
      (props.method === "OXXO" && props.payChannel === "Bamboo") ||
      (props.method === "OXXO" && props.payChannel === "Localpayment")
    ) {
      let endpoint = "pdf";
      let endpointPng = "png";
      if (props.method === "OXXO" && props.payChannel === "CardPay") {
        endpoint = "oxxo-pdf";
      } else if (
        props.method === "Boleto" &&
        (props.payChannel === "BS2" ||
          props.payChannel === "StarkBank" ||
          props.payChannel === "Mercadopago" ||
          props.payChannel === "CHANNEL_GROUP_ROUTER")
      ) {
        endpoint = "boleto-pdf";
        endpointPng = "boleto-png";
      }

      req.post(`api/trade/${endpoint}?prepay_id=${props.prepayId}`).then((res) => {
        if (res.data.code === "10000") {
          res.data?.data && setPdf(res.data.data);
          req.post(`api/trade/${endpointPng}?prepay_id=${props.prepayId}`).then((res) => {
            if (res.data.code === "10000") {
              res.data?.data && setImage(res.data.data);
            }
          });
        }
      });
    } else if (props.method === "OTC" && props.payChannel === "Xendit") {
      req
        .post(`/api/trade/otc-code?prepay_id=${props.prepayId}`)
        .then((res) => {
          if (res.data?.code === "10000") {
            setOtcData(res.data?.data);
          } else {
            props.setTradeStatus({ id: "fail", text: "Authorization failed" });
            history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
          }
        })
        .catch((err) => {
          props.setTradeStatus({ id: "fail", text: "Authorization failed" });
          history.replace(`/result?prepay_id=${props.prepayId}${returnUrlAsParam()}`);
        });
    } else if (
      (props.method === "Cash" || props.method === "BankTransfer") &&
      props.payChannel === "SafetyPay" &&
      !props.channel
    ) {
      req
        .post(`/api/trade/get-banktransfer-channel?prepay_id=${props.prepayId}`, {
          method: props.method,
          channel: props.payChannel,
          bank_id: props.tradeInfo?.payment_request?.bank,
        })
        .then((res) => {
          if (res.data.code === "10000") {
            props.setChannel({
              data: Array.isArray(res.data?.data)
                ? res.data?.data.filter((item) => {
                    if (props.method === "BankTransfer") {
                      return item.channel === "Online";
                    } else if (props.method === "Cash") {
                      if (props.tradeInfo?.app_info?.region === "PER") {
                        return item.tag === "Agents";
                      } else {
                        return item.channel === "Cash";
                      }
                    } else {
                      return true;
                    }
                  })
                : [],
            });
          }
        })
        .catch(() => {
          console.log("/get-banktransfer-channel error!");
        });
    }
  }, []);
  const hideFooter =
    ((props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay") ||
    (props.method === "DepositExpress" && props.payChannel === "Levpay");
  const isSafetyPay = (props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay";
  const notify = (text) => {
    toast.error(text, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleError = (err, notice) => {
    console.error(err);
    setIsLoading(false);
    notify(t(notice || "fail_change_method"));
  };

  return (
    <>
      <AutoQuery />
      <div className="puff__loader" style={{ display: isLoading ? "block" : "none" }}>
        <LottiAnimation anime="loadApi" height={100} className="loader" />
      </div>
      {props.desktopView ? (
        <div className="checkout">
          <div className="checkout__container">
            <div className="checkout__left">
              {(props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay" && (
                <CheckoutSafetyPay />
              )}
              {props.method === "SPEI" &&
                (props.payChannel === "OpenPay" ||
                  props.payChannel === "STP" ||
                  props.payChannel === "Banwire" ||
                  props.payChannel === "PGOne") && <CheckoutSpei pdf={pdf} image={image} />}
              {props.method === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay") && (
                <CheckoutPaynet pdf={pdf} image={image} />
              )}
              {((props.method === "OXXO" &&
                (props.payChannel === "Banwire" ||
                  props.payChannel === "CardPay" ||
                  props.payChannel === "Bamboo" ||
                  props.payChannel === "Localpayment")) ||
                (props.method === "OXXOPay" && (props.payChannel === "Conekta" || props.payChannel === "PGOne"))) && (
                <CheckoutOxxo pdf={pdf} image={image} />
              )}
              {((props.method === "Wallet" &&
                (props.payChannel === "Mach" ||
                  props.payChannel === "Vita" ||
                  props.payChannel === "AME" ||
                  props.payChannel === "AlipayCN" ||
                  props.payChannel === "AlipayHK" ||
                  props.payChannel === "GCash" ||
                  props.payChannel === "KakaoPayAlipay" ||
                  props.payChannel === "TNG" ||
                  props.payChannel === "TrueMoney" ||
                  props.payChannel === "RabbitLinePay" ||
                  props.payChannel === "TPaga" ||
                  props.payChannel === "Fpay" ||
                  props.payChannel === "GrabPay" ||
                  props.payChannel === "OVO")) ||
                (props.method === "PlayersBankPIX" && props.payChannel === "BS2") ||
                (props.method === "SBP" && props.payChannel === "PGOne") ||
                (props.method === "SberPay" && props.payChannel === "PGOne")) && <CheckoutWallet />}
              {props.method === "Wallet" && props.payChannel === "FawryPay" && <CheckoutQr />}
              {props.method === "CoDi" && (props.payChannel === "STP" || props.payChannel === "PGOne") && (
                <CheckoutCodi />
              )}
              {(((props.method === "Boleto" || props.method === "BoletoRapido") && props.payChannel === "BS2") ||
                (props.method === "Boleto" && props.payChannel === "StarkBank") ||
                (props.method === "Boleto" && props.payChannel === "Mercadopago") ||
                (props.method === "Boleto" && props.payChannel === "CHANNEL_GROUP_ROUTER")) && (
                <CheckoutBoleto pdf={pdf} />
              )}
              {props.method === "PIX" &&
                (props.payChannel === "BS2" ||
                  props.payChannel === "Bradesco" ||
                  props.payChannel === "Rendimento" ||
                  props.payChannel === "Nupay" ||
                  props.payChannel === "Genial" ||
                  props.payChannel === "StarkBank" ||
                  props.payChannel === "CHANNEL_GROUP_ROUTER" ||
                  props.payChannel === "Mercadopago" ||
                  props.payChannel === "StarkBankTransferSmile" ||
                  props.payChannel === "BTG" ||
                  props.payChannel === "Fastcash" ||
                  props.payChannel === "Pagsmile" ||
                  props.payChannel === "Delbank" ||
                  props.payChannel === "Starpago") && <CheckoutPix />}
              {props.method === "Lottery" && (props.payChannel === "Caixa" || props.payChannel === "Mercadopago") && (
                <CheckoutLottery />
              )}
              {props.method === "DepositExpress" && props.payChannel === "Levpay" && (
                <CheckoutDepositExpress setIsLoading={setIsLoading} handleError={handleError} />
              )}
              {props.method === "OTC" && props.payChannel === "Xendit" && <CheckoutSeven11 data={otcData} />}
              {(props.method === "KLAP" || props.method === "Cash") && props.payChannel === "Multicaja" && (
                <CheckoutKlapCash />
              )}
              {/* {props.method === "Cash" && props.payChannel === "PayCash" && <CheckoutPayCash />} */}
              {((props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
                ((props.method === "SuRed" ||
                  props.method === "GANA" ||
                  props.method === "Bancolombia" ||
                  props.method === "BCR" ||
                  props.method === "BNCR") &&
                  props.payChannel === "PayValida") ||
                (props.method === "BNCR" && props.payChannel === "PayCash")) && <CheckoutPayvalida />}
              {(((props.method === "PagoFacil" ||
                props.method === "Servipag" ||
                props.method === "Rapipago" ||
                props.method === "Cash") &&
                props.payChannel === "Localpayment") ||
                (props.method === "PagoFacil" && props.payChannel === "PagoFacil") ||
                (props.method === "Rapipago" && props.payChannel === "Rapipago")) && <CheckoutBarcode />}

              {props.method === "BankTransfer" && props.payChannel === "Localpayment" && (
                <CheckoutBankTransferLocalpayment />
              )}

              {props.method === "Transfiya" && props.payChannel === "Movii" && <CheckoutTransfiya />}

              {(((props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet") ||
                (props.method === "Cash" &&
                  (props.payChannel === "PayCash" || (props.payChannel === "PGOne" && region === "MEX"))) ||
                ((props.method === "BankTransfer" || props.method === "Cash") &&
                  (props.payChannel === "KASHIO" || (props.payChannel === "PGOne" && region === "PER")))) && (
                <CheckoutBankCashCommon />
              )}

              {((props.method === "Crypto" && props.payChannel === "PGOne") ||
                (props.method === "Binance" && props.payChannel === "PGOne")) && <CheckoutCrypto />}

              {((props.method === "Wallet" && (props.payChannel === "Tupay" || props.payChannel === "ShopeePay")) ||
                (props.method === "QRCode" && props.payChannel === "PGOne" && region === "PER")) && <CheckoutQr />}

              {props.method === "Multibanco" && props.payChannel === "PPRO" && <CheckoutMultibanco />}
              {props.method === "Cash" && props.payChannel === "FawryPay" && <CheckoutFawryCash />}
            </div>
            <div className="checkout__right">
              <OrderDetails showDetails={!isSafetyPay} showStatus={isSafetyPay} backToShop={true} />
            </div>
          </div>
        </div>
      ) : (
        <div className="checkoutMobile" style={{ paddingBottom: isSafetyPay ? "300px" : "90px" }}>
          {/*   Mobile starts here  */}
          <div className="checkoutMobile__top">
            <TitleWithDetails show={true} />
            <hr />
            {props?.payData?.amount &&
              props?.paymentInfo?.pay_amount &&
              props?.payData?.amount != props?.paymentInfo?.pay_amount && (
                <div className="checkout--floatRight">
                  <p>
                    <span className="checkoutMobile__top__totalText" style={{ fontSize: "13px" }}>
                      {t("fee_102")}
                    </span>
                    <span className="checkoutMobile__top__totalValue" style={{ fontSize: "14px" }}>
                      {props?.paymentInfo?.pay_currency || props?.tradeInfo?.pay_currency}{" "}
                      {(
                        props?.payData?.amount - (props?.paymentInfo?.order_amount || props?.tradeInfo?.order_amount)
                      )?.toFixed(2)}
                    </span>
                  </p>
                </div>
              )}
            <div className="checkout--floatRight">
              <p>
                <span className="checkoutMobile__top__totalText">{t("total")}</span>
                <span className="checkoutMobile__top__totalValue">
                  <FormatMoney type="total" />
                </span>
              </p>
            </div>
            <hr />
          </div>
          {(props.method === "Cash" || props.method === "BankTransfer") && props.payChannel === "SafetyPay" && (
            <CheckoutSafetyPay />
          )}
          {props.method === "SPEI" &&
            (props.payChannel === "OpenPay" ||
              props.payChannel === "STP" ||
              props.payChannel === "Banwire" ||
              props.payChannel === "PGOne") && <CheckoutSpei pdf={pdf} image={image} mobile />}
          {props.method === "Cash" && (props.payChannel === "Banwire" || props.payChannel === "OpenPay") && (
            <CheckoutPaynet pdf={pdf} image={image} mobile />
          )}
          {((props.method === "OXXO" &&
            (props.payChannel === "Banwire" ||
              props.payChannel === "CardPay" ||
              props.payChannel === "Bamboo" ||
              props.payChannel === "Localpayment")) ||
            (props.method === "OXXOPay" && (props.payChannel === "Conekta" || props.payChannel === "PGOne"))) && (
            <CheckoutOxxo pdf={pdf} image={image} mobile />
          )}
          {((props.method === "Wallet" &&
            (props.payChannel === "Mach" ||
              props.payChannel === "Vita" ||
              props.payChannel === "AME" ||
              props.payChannel === "AlipayCN" ||
              props.payChannel === "AlipayHK" ||
              props.payChannel === "GCash" ||
              props.payChannel === "KakaoPayAlipay" ||
              props.payChannel === "TNG" ||
              props.payChannel === "TrueMoney" ||
              props.payChannel === "RabbitLinePay" ||
              props.payChannel === "TPaga" ||
              props.payChannel === "Fpay" ||
              props.payChannel === "GrabPay" ||
              props.payChannel === "OVO")) ||
            (props.method === "PlayersBankPIX" && props.payChannel === "BS2") ||
            (props.method === "SBP" && props.payChannel === "PGOne") ||
            (props.method === "SberPay" && props.payChannel === "PGOne")) && <CheckoutWallet />}
          {props.method === "Wallet" && props.payChannel === "FawryPay" && <CheckoutQr />}
          {props.method === "CoDi" && (props.payChannel === "STP" || props.payChannel === "PGOne") && <CheckoutCodi />}
          {(((props.method === "Boleto" || props.method === "BoletoRapido") && props.payChannel === "BS2") ||
            (props.method === "Boleto" && props.payChannel === "StarkBank") ||
            (props.method === "Boleto" && props.payChannel === "Mercadopago") ||
            (props.method === "Boleto" && props.payChannel === "CHANNEL_GROUP_ROUTER")) && (
            <CheckoutBoleto pdf={pdf} mobile image={image} />
          )}
          {props.method === "PIX" &&
            (props.payChannel === "BS2" ||
              props.payChannel === "Bradesco" ||
              props.payChannel === "Rendimento" ||
              props.payChannel === "Nupay" ||
              props.payChannel === "Genial" ||
              props.payChannel === "StarkBank" ||
              props.payChannel === "CHANNEL_GROUP_ROUTER" ||
              props.payChannel === "Mercadopago" ||
              props.payChannel === "StarkBankTransferSmile" ||
              props.payChannel === "BTG" ||
              props.payChannel === "Fastcash" ||
              props.payChannel === "Pagsmile" ||
              props.payChannel === "Delbank" ||
              props.payChannel === "Starpago") && <CheckoutPix />}
          {props.method === "Lottery" && (props.payChannel === "Caixa" || props.payChannel === "Mercadopago") && (
            <CheckoutLottery />
          )}
          {props.method === "DepositExpress" && props.payChannel === "Levpay" && (
            <CheckoutDepositExpress setIsLoading={setIsLoading} handleError={handleError} />
          )}
          {props.method === "OTC" && props.payChannel === "Xendit" && <CheckoutSeven11 data={otcData} />}
          {(props.method === "KLAP" || props.method === "Cash") && props.payChannel === "Multicaja" && (
            <CheckoutKlapCash />
          )}
          {/* {props.method === "Cash" && props.payChannel === "PayCash" && <CheckoutPayCash />} */}
          {((props.method === "Baloto" && (props.payChannel === "PayValida" || props.payChannel === "IGT")) ||
            ((props.method === "SuRed" ||
              props.method === "GANA" ||
              props.method === "Bancolombia" ||
              props.method === "BCR" ||
              props.method === "BNCR") &&
              props.payChannel === "PayValida") ||
            (props.method === "BNCR" && props.payChannel === "PayCash")) && <CheckoutPayvalida />}
          {(((props.method === "PagoFacil" ||
            props.method === "Servipag" ||
            props.method === "Rapipago" ||
            props.method === "Cash") &&
            props.payChannel === "Localpayment") ||
            (props.method === "PagoFacil" && props.payChannel === "PagoFacil") ||
            (props.method === "Rapipago" && props.payChannel === "Rapipago")) && <CheckoutBarcode />}

          {props.method === "BankTransfer" && props.payChannel === "Localpayment" && (
            <CheckoutBankTransferLocalpayment />
          )}
          {props.method === "Transfiya" && props.payChannel === "Movii" && <CheckoutTransfiya />}
          {(((props.method === "BankTransfer" || props.method === "Cash") && props.payChannel === "Monnet") ||
            (props.method === "Cash" &&
              (props.payChannel === "PayCash" || (props.payChannel === "PGOne" && region === "MEX"))) ||
            ((props.method === "BankTransfer" || props.method === "Cash") &&
              (props.payChannel === "KASHIO" || (props.payChannel === "PGOne" && region === "PER")))) && (
            <CheckoutBankCashCommon />
          )}
          {((props.method === "Crypto" && props.payChannel === "PGOne") ||
            (props.method === "Binance" && props.payChannel === "PGOne")) && <CheckoutCrypto />}

          {((props.method === "Wallet" && (props.payChannel === "Tupay" || props.payChannel === "ShopeePay")) ||
            (props.method === "QRCode" && props.payChannel === "PGOne" && region === "PER")) && <CheckoutQr />}

          {props.method === "Multibanco" && props.payChannel === "PPRO" && <CheckoutMultibanco />}
          {props.method === "Cash" && props.payChannel === "FawryPay" && <CheckoutFawryCash />}
          {!hideFooter && props.returnUrl && (
            <div className="checkoutMobile__footer">
              <button
                className="checkoutMobile__footer__returnButton"
                onClick={() => {
                  window.location.replace(props.returnUrl);
                }}
              >
                {t("back_shop")}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state, ownprops) => {
  return {
    desktopView: state.desktopView,
    method: state.method,
    payChannel: state.payChannel,
    channel: state.channel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    prepayId: state.prepayId,
    returnUrl: state.returnUrl,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChannel: (item) => dispatch(setChannel(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
